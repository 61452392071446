<template>
  <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <div class="mb-10 font-weight-bold text-dark" v-if="formData && referenceData">

      <b-card border-variant="primary" body-class="px-7 py-5" class="mb-10">
        <div v-if="formData.brand === 'Rentsure' || formData.brand === 'CRI'" class="font-weight-bold font-size-lg">
          Our policy covers the following vehicles:
          <div class="ml-10 mt-2">
            <ul>
              <li>Passenger vehicles</li>
              <li>Commercial vehicles</li>
            </ul>
          </div>
          The following vehicles are not in our appetite for cover:
          <div class="ml-10 mt-2">
            <ul>
              <li>Exotic vehicles</li>
              <li>Caravans and motorhomes</li>
              <li>High-end prestige</li>
              <li>Modified, muscle, collectible, custom vehicles, or vintage</li>
              <li>Heavy commercial</li>
              <li>Plant and machinery</li>
              <li>Courier</li>
              <li>Taxi (Ride-share is covered)</li>
              <li>Off-road vehicles</li>
              <li>Motorcycles</li>
            </ul>
          </div>
          Please note, our minimum fleet requirement is 10 vehicles for standard self-drive rental business.<br/>
          For Rideshare or Accident Replacement rental businesses, the minimum fleet requirement is 20 Vehicles, and a
          minimum of 3 years claims experience on insurer's letterhead in order to review and quote.
        </div>

        <div v-if="formData.brand === 'Fuse'" class="font-weight-bold font-size-lg">
          Our policy covers the following vehicles:
          <div class="ml-10 mt-2">
            <ul>
              <li>Passenger vehicles</li>
              <li>Commercial vehicles</li>
              <li>Plant & machinery (up to 20% of combined market value of the fleet)</li>
            </ul>
          </div>
          The following vehicles are not in our appetite for cover:
          <div class="ml-10 mt-2">
            <ul>
              <li>Exotic vehicles</li>
              <li>Caravans and motorhomes</li>
              <li>High-end prestige</li>
              <li>Modified, muscle, collectible, custom vehicles, or vintage</li>
              <li>Courier</li>
              <li>Taxi (Ride-share is covered)</li>
              <li>Off-road vehicles</li>
              <li>Motorcycles</li>
            </ul>
          </div>
          Please note, our minimum fleet requirement is 15 vehicles.<br/>
        </div>
      </b-card>

      <div v-if="formData.brand === 'Fuse'" class="form-group row mb-0">
        <div class="col-6">
          <span class="font-weight-bold font-size-h4">
            Is the person initiating this proposal form a Broker?<span class="text-danger">*</span>
          </span>
          <b-form-group class="mt-2">
            <ValidationProvider rules="required" name="personCompletingForm" v-slot="{ errors }">
              <b-form-radio-group type="radio" v-model="formData.personCompletingForm.type">
                <b-form-radio type="radio" value="broker">Yes</b-form-radio>
                <b-form-radio type="radio" value="client">No</b-form-radio>
              </b-form-radio-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </div>
      </div>

      <div class="mb-4" v-if="formData.brand === 'CRI' || formData.personCompletingForm.type === 'broker'">
        <span class="font-weight-bold">
          IMPORTANT: If you are a general insurance broker completing this form on behalf of your client, please enter
          the client contact details in the 'Client Contacts' section on the next page.
        </span>

        <brokerage-details :brokerage-details="formData.brokerageDetails" @brokerContactUpdated="brokerContactUpdated"
                           :is-read-only="isReadOnly"></brokerage-details>
      </div>

    </div>
  </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import BrokerageDetails from "@/view/pages/wizard/motor-proposal-form/components/BrokerageDetails.vue";

export default {
  name: "WizardPage1",

  components: {
    BrokerageDetails,
  },

  props: {
    formData: Object,
    referenceData: Object,
    isReadOnly: Boolean
  },
  
  data() {
    return {
    };
  },

  methods: {

    brokerContactUpdated: function() {
      // For CRI - Broker-contact is always the client's primary-contact
      if(this.formData.brand === 'CRI' || this.formData.personCompletingForm.type === 'broker') {
        // Copy broker-contact to primary-contact
        let brokerContact = _.get(this.formData, 'brokerageDetails.brokerContactDetails');
        let primaryContact = _.get(this.formData, 'clientContacts.primaryContact');

        if(brokerContact && primaryContact) {
          this.$set(primaryContact, 'firstName', brokerContact.firstName);
          this.$set(primaryContact, 'lastName', brokerContact.lastName);
          this.$set(primaryContact, 'title', brokerContact.title);
          this.$set(primaryContact, 'email', brokerContact.email);
          this.$set(primaryContact, 'phone', brokerContact.phone);
          this.$set(primaryContact, 'mobile', brokerContact.mobile);
        }
      }
    }
  },
};
</script>
