<template>
  <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">
    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
      <div class="mb-10 font-weight-bold text-dark" v-if="formData && referenceData">

        <div v-if="!isRenewal">
        <span class="font-size-lg">
          <strong>Is this a start-up (not yet trading) or an established business? <span class="text-danger">*</span></strong>
        </span>
        <b-form-group>
          <ValidationProvider rules="required" name="businessType" v-slot="{ errors }">
            <b-form-radio-group
              id="radio-group-business-type" name="business-type" type="radio"
              v-model="formData.businessType" :disabled="isReadOnly"
            >
              <b-form-radio type="radio" value="new">Start-up</b-form-radio>
              <b-form-radio type="radio" value="established">Established business</b-form-radio>
            </b-form-radio-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-form-group>

        <div v-if="formData.businessType === 'established'" class="font-weight-bold font-size-lg">
        To receive a quotation, please ensure you include the following information in your submission:
          <div class="ml-10 mt-2">
            <ul>
              <li>
                Fleet details in an Excel spreadsheet noting make, model, year,
                vehicle State or Territory of registration and current market
                value.
              </li>
              <li v-if="formData.brand !== 'Fuse'">
                A copy of the Rental Agreement, including the Terms and Conditions
                which we will need to check and approve. Please note that lease
                agreements are not acceptable.
              </li>
              <li>
                Claims history for the past five years on the insurer's
                letterhead.
              </li>
            </ul>
          </div>
        </div>

        <div v-if="formData.businessType === 'new'" class="font-size-lg font-weight-bold">
          Starting a new<span v-if="formData.brand !== 'Fuse'"> rental</span> business is exciting and you will have
          lots of questions. We are here to help, so please feel free to call our team before you complete the form on
          <a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>.<br /><br />
          Please remember our appetite and minimum premiums before you complete your submission. If you are unsure of
          anything, Please contact us.<br /><br />
          <span v-if="formData.brand !== 'Fuse'">
          We can assist with rental agreements and have partners who can navigate
          this complex area noting we do not provide specific advice on rental
          agreement, only general advice. You should contact your solicitor to
          finalise a contract suitable for your business.<br /><br />
          </span>
          To enable us to provide a quotation, please ensure you have available:<br /><br />

          <div class="ml-10 mt-2">
            <ul>
              <li>Your Fleet in Excel format.</li>
              <li v-if="formData.brand !== 'Fuse'">
                Rental Agreement.<br/>
                If you are a self-drive business here's a template to help you with this process -
                <a :href="rentalAgreementLink" target="_blank"><u>Click here</u></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
        <!-- begin: Insured Entity Details  -->
        <h3>INSURED ENTITY</h3>
        <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
          <insured-entity :legal-entity="formData.insuredEntity" :is-read-only="isReadOnly" :reference="'insured'"
                          :show-abn-search="!isRenewal" :show-address="formData.brand === 'Rentsure'" :is-uw-mode="isUwMode">
          </insured-entity>
        </b-card>
        <!-- end: Insured Entity Details  -->

        <!-- begin: Additional Insured Entities -->
        <h3>ADDITIONAL INSUREDS</h3>
        <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
          <table>
            <thead>
              <th style="width: 20%"></th>
            </thead>
            <tr v-for="(legalEntity, index) in formData.additionalInsuredList" :key="index">
              <td>
                <b-card class="mb-4" bg-variant="light">
                  <div class="row">
                    <div class="col-12">
                      <label class="text-left">Legal Entity {{ index + 1 }}</label>
                      <button class="btn btn-danger m-2 float-right" @click.prevent="removeAdditionalInsuredEntry(index)" v-if="!isReadOnly">
                        Remove
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <insured-entity :legal-entity="legalEntity" :is-read-only="isReadOnly" :reference="`${index}`" />
                    </div>
                  </div>
                </b-card>
              </td>
            </tr>
          </table>
          <div>
            <button class="btn btn-primary m-2" @click.prevent="addAdditionalInsuredEntry()" v-if="!isReadOnly">
              Add Legal Entity
            </button>
          </div>
        </b-card>
        <!-- end: Additional Insured Entities -->

        <!-- begin: Client Contact Details -->
        <h3>CLIENT CONTACTS</h3>
        <b-card border-variant="primary" class="mb-6" body-class="px-7 py-5">
          <b-card-title class="mb-1">Primary Contact</b-card-title>
          <span v-if="formData.formType === 'Motor Proposal Form' && (formData.brand === 'CRI' || formData.personCompletingForm.type === 'broker')">
            Broker Contact details from the previous page are pre-filled here.
          </span>
          <span v-if="formData.formType === 'Renewal Declaration Form' && (formData.brand === 'CRI' || formData.personCompletingForm.type === 'broker')">
            Primary contact must be a broker contact.
          </span>

          <client-contact :contact-details="formData.clientContacts.primaryContact"
                          :mandatory="formData.brand === 'Rentsure' || formData.formType === 'Motor Proposal Form'"
                          :is-read-only="isReadOnly || formData.brand === 'CRI' || formData.personCompletingForm.type === 'broker'"></client-contact>

          <b-card-title class="mb-1 mt-10">Secondary Contact</b-card-title>
          <client-contact :contact-details="formData.clientContacts.secondaryContact"
                          :is-read-only="isReadOnly"></client-contact>
        </b-card>
        <!-- end: Client Contact Details -->
    </div>
  </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import ClientContact from "@/view/pages/wizard/motor-proposal-form/components/ClientContact.vue";
import InsuredEntity from "@/view/pages/wizard/motor-proposal-form/components/InsuredEntity.vue";
import _ from "lodash";

export default {
  name: "WizardPage2",

  components: {
    ClientContact,
    InsuredEntity
  },

  props: {
    formData: Object,
    referenceData: Object,
    isReadOnly: Boolean,
    isRenewal: Boolean,
    isUwMode: Boolean
  },
  
  data() {
    return {
    };
  },

  computed: {
    rentalAgreementLink: function () {
      if(this.formData) {
        let brand = _.toUpper(_.get(this.formData, 'brand'));
        if(brand === 'RENTSURE') {
          return 'https://link.rentsure.com.au/sample-rental-agreement';
        }
        else if(brand === 'CRI') {
          return 'https://link.crinsurance.com.au/sample-rental-agreement';
        }
      }
      return '';
    },

    phoneNumber: function() {
      const brand = _.toUpper(_.get(this.formData, 'brand'));
      let phone = null;
      switch(brand) {
        case 'RENTSURE': phone = '1800 355 646'; break;
        case 'CRI': phone = '1300 675 050'; break;
        case 'FUSE': phone = '1300 387 338'; break;
      }
      return phone;
    }
  },

  methods: {
    addAdditionalInsuredEntry: function () {
      this.formData.additionalInsuredList.push({});
    },

    removeAdditionalInsuredEntry: function (index) {
      this.formData.additionalInsuredList.splice(index, 1);
    }
  },
};
</script>
