<template>
<ValidationObserver ref="proposalForm">
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="proposalFormWizard"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
<!--        <div class="wizard-nav border-bottom mb-1 mb-lg-5" v-show="formData && referenceData">-->
<!--          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">-->
<!--            <div-->
<!--              v-for="(wizardPage, index) in wizardPages"-->
<!--              v-bind:key="index"-->
<!--              class="wizard-step"-->
<!--              data-wizard-type="step">-->
<!--              <div class="wizard-label">-->
<!--                <h3 class="wizard-title">-->
<!--                  <span>{{ index + 1 }}</span-->
<!--                  >{{ wizardPage.title }}-->
<!--                </h3>-->
<!--                <div class="wizard-bar"></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
              <div class="col-xl-12 col-xxl-8">
                <!--begin: Wizard Form-->
                <form class="form mt-0" id="kt_form">

<!--                  <b-alert v-if="formData && !isFormEditable" variant="secondary" show>-->
<!--                    <div class="text-dark">This form is already submitted and cannot be modified.</div>-->
<!--                  </b-alert>-->

                  <!--begin: ERROR ALERT-->
                  <b-alert v-if="errorAlert" variant="warning" show>
                    <div class="text-dark" v-html="errorAlert"></div>
                  </b-alert>
                  <!--end: ERROR ALERT-->

                  <!--begin: Wizard Step 1 - Do not show for renewal -->
                  <wizard-page1 v-if="formData && !isRenewalProposalForm" :form-data="formData" :reference-data="referenceData" :is-read-only="!isFormEditable"></wizard-page1>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <wizard-page2 :form-data="formData" :reference-data="referenceData" :is-read-only="!isFormEditable" :is-renewal="isRenewalProposalForm"></wizard-page2>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <wizard-page3 :form-data="formData" :reference-data="referenceData" :is-read-only="!isFormEditable" :is-renewal="isRenewalProposalForm"
                                :upload-progress="uploadProgress"></wizard-page3>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <wizard-page4 :form-data="formData" :reference-data="referenceData" :is-read-only="!isFormEditable" :is-renewal="isRenewalProposalForm"></wizard-page4>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5 - Do not show for renewal -->
                  <wizard-page5 v-if="formData && !isRenewalProposalForm" :form-data="formData" :reference-data="referenceData" :is-read-only="!isFormEditable"
                                :is-renewal="isRenewalProposalForm" :upload-progress="uploadProgress"></wizard-page5>
                  <!--end: Wizard Step 5-->

                  <!--begin: Wizard Step 6-->
                  <wizard-page6 :form-data="formData" :reference-data="referenceData" :is-read-only="!isFormEditable"
                                :is-renewal="isRenewalProposalForm" :upload-progress="uploadProgress"></wizard-page6>
                  <!--end: Wizard Step 6-->

                  <!--begin: Wizard Step 7-->
                  <wizard-page7 :form-data="formData" :reference-data="referenceData" ref="wizardPage7" :is-read-only="!isFormEditable" :is-renewal="isRenewalProposalForm"></wizard-page7>
                  <!--end: Wizard Step 7-->

<!--                  <b-alert v-if="formData && !isFormEditable" variant="secondary" show>-->
<!--                    <div class="text-dark">This form is already submitted and cannot be modified.</div>-->
<!--                  </b-alert>-->

                  <!--begin: Wizard Actions -->
<!--                  <div class="d-flex justify-content-between border-top pt-10">-->
<!--                    <div class="mr-2">-->
<!--                      <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"-->
<!--                              data-wizard-type="action-prev"-->
<!--                      >-->
<!--                        Previous Step-->
<!--                      </button>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <button-->
<!--                        v-if="formData && referenceData && !isLastPage"-->
<!--                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"-->
<!--                        v-on:click.prevent="goToNextPage"-->
<!--                      >-->
<!--                        Next Step-->
<!--                      </button>-->
<!--                    </div>-->
<!--                    -->
<!--                    <button-->
<!--                      v-if="isSubmitButtonVisible"-->
<!--                      class="btn btn-success font-weight-bold text-uppercase px-9 py-4"-->
<!--                      v-on:click.prevent="submitForm"-->
<!--                    >-->
<!--                      Submit-->
<!--                    </button>-->
<!--                  </div>-->
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-disabled {
  cursor: not-allowed !important;
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import _ from "lodash";
import KTUtil from "@/assets/js/components/util";
// import KTWizard from "@/assets/js/components/wizard";
import stringify from "fast-json-stable-stringify";
// import Swal from "sweetalert2";

import WizardPage1 from "@/view/pages/wizard/motor-proposal-form/pages/WizardPage1";
import WizardPage2 from "@/view/pages/wizard/motor-proposal-form/pages/WizardPage2";
import WizardPage3 from "@/view/pages/wizard/motor-proposal-form/pages/WizardPage3";
import WizardPage4 from "@/view/pages/wizard/motor-proposal-form/pages/WizardPage4";
import WizardPage5 from "@/view/pages/wizard/motor-proposal-form/pages/WizardPage5";
import WizardPage6 from "@/view/pages/wizard/motor-proposal-form/pages/WizardPage6";
import WizardPage7 from "@/view/pages/wizard/motor-proposal-form/pages/WizardPage7";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import BrandService from "@/core/services/brand.service";

export default {
  name: "MotorProposalForm",

  components: {
    WizardPage1,
    WizardPage2,
    WizardPage3,
    WizardPage4,
    WizardPage5,
    WizardPage6,
    WizardPage7,
  },

  data() {
    return {
      serverUrl: null,
      formId: null,
      wizardObj: null,
      errorAlert: null,

      formData: null,
      formDataString: null,
      referenceData: null,

      uploadProgress: {}
    };
  },

  mounted() {
    // Show page loading
    this.startPageLoading();

    // Extract information from URL params
    const queryParams = this.$route.query;
    if (!queryParams.formId) {
      this.handleError("Unauthorised access!");
      return;
    }

    // Extract data from URL params
    this.formId = queryParams.formId;
    this.userId = queryParams.userId || null;

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV === 'development') {
      this.serverUrl = `http://localhost:3000/services`;
    }
    this.$apiService.setServerUrl(this.serverUrl);

    // Extract token from URL params and set it in the session
    if(queryParams.token) {
      this.$apiService.saveTokenAndSetHeader(queryParams.token);
    }

    // Get Application Data
    this.getFormDataAndInitialiseUI();
  },

  computed: {

    // wizardPages: function() {
    //   let wizardPages = [
    //     { pageNumber: 1, title: "Your Details", id: 'wizardPage1'},
    //     { pageNumber: 2, title: "The Insured", id: 'wizardPage2'},
    //     { pageNumber: 3, title: "Business Info", id: 'wizardPage3'},
    //     { pageNumber: 4, title: "Rental Locations", id: 'wizardPage4'},
    //     { pageNumber: 5, title: "Insurance Details", id: 'wizardPage5'},
    //     { pageNumber: 6, title: "Fleet Details", id: 'wizardPage6'},
    //     { pageNumber: 7, title: "Declaration", id: 'wizardPage7'},
    //   ];
    //
    //   // Renewal Remove 'Your Details' page (WizardPage1) and 'Insurance Details' page (WizardPage5)
    //   if(this.isRenewalProposalForm) {
    //     wizardPages = [
    //       { pageNumber: 1, title: "The Insured", id: 'wizardPage2'},
    //       { pageNumber: 2, title: "Business Info", id: 'wizardPage3'},
    //       { pageNumber: 3, title: "Rental Locations", id: 'wizardPage4'},
    //       { pageNumber: 4, title: "Fleet Details", id: 'wizardPage6'},
    //       { pageNumber: 5, title: "Declaration", id: 'wizardPage7'},
    //     ];
    //   }
    //
    //   return wizardPages;
    // },

    isRenewalProposalForm: function() {
      return this.formData && this.formData.formType === 'Renewal Declaration Form';
    },

    isFormEditable: function() {
      return false;
      // return this.formData && this.referenceData &&
      //     (this.formData.formStatus === 'Initialised' || this.formData.formStatus === 'Incomplete');
    },

    // isSubmitButtonVisible: function() {
    //   return this.isFormEditable && this.isLastPage;
    // },

    // isLastPage: function() {
    //   return this.wizardObj && (this.wizardObj.getStep() === this.wizardPages.length);
    // }
  },

  methods: {
    // initWizard: function () {
    //   // Initialize form wizard
    //   const wizard = new KTWizard("proposalFormWizard", {
    //     startStep: 1, // initial active step number
    //     clickableSteps: true, // allow step clicking
    //   });
    //
    //   this.wizardObj = wizard;
    //
    //   // Before-Change event - Save app-data to server
    //   wizard.on("beforeChange", (wizardObj, newPage) => {
    //     this.clearError();
    //
    //     if (this.formData) {
    //       // Save current page number in form-data.
    //       this.formData.currentPage = newPage;
    //
    //       // Save app-data to server
    //       this.saveFormData();
    //     }
    //   });
    //
    //   // Change event - Scroll to top of the page
    //   wizard.on("change", (/*wizardObj*/) => {
    //     setTimeout(function () {
    //       KTUtil.scrollTop();
    //     }, 500);
    //   });
    // },

    // goToNextPage: async function() {
    //   this.clearError();
    //   let isNavAllowed = true;
    //
    //   if(this.isFormEditable) {
    //     // Validate the current page
    //     isNavAllowed = await this.isPageValid(this.wizardObj.getStep());
    //   }
    //
    //   if(isNavAllowed){
    //     this.wizardObj.goNext(true);
    //   }
    //   else {
    //     this.setError('Please complete all fields on this page.');
    //   }
    // },

    setTitles: function () {
      let title = "Motor Proposal Form";
      let subTitle = null;
      if(this.isRenewalProposalForm) {
        title = "Renewal Declaration";
        subTitle = _.get(this.formData, 'insuredEntity.fullInsuredName');
      }

      this.$store.commit("setWizardTitle", title);
      if(subTitle) {
        this.$store.commit("setWizardSubtitle", subTitle);
      }
    },

    getFormDataAndInitialiseUI: function() {
      Promise.all([
        this.$apiService.get(`${this.serverUrl}/mpf/form/${this.formId}`, { params: { userId: this.userId }}),
        this.$apiService.get(`${this.serverUrl}/mpf/reference-data`)
      ])
        .then((values) => {
          this.formData = values[0].data.result;
          this.referenceData = values[1].data.result;
          this.formDataString = stringify(this.formData);

          // Set UI Brand
          BrandService.setUIBrand(this.formData.brand);

          this.$nextTick(()=> {
            // Set wizard title and subtitle
            this.setTitles();

            // Initialise the UI wizard
            // this.initWizard();

            // Set starting step for the wizard
            // let startStep = this.formData.currentPage || 1;
            // if(!this.isFormEditable) {
            //   startStep = 1;
            // }
            // if (startStep !== 1) {
            //   this.wizardObj.goTo(startStep, false);
            // }

            setTimeout(() => {
              this.stopPageLoading();
            }, 0);
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    // saveFormData: function () {
    //   if (!this.isFormEditable) {
    //     return;
    //   }
    //
    //   // Save form-data to the server - only if it has changed
    //   let newFormDataString = stringify(this.formData);
    //   if (newFormDataString !== this.formDataString) {
    //     // Show page loading
    //     this.startPageLoading();
    //
    //     this.$apiService.post(`${this.serverUrl}/mpf/form/${this.formId}`, {
    //       formData: this.formData
    //     })
    //       .then(() => {
    //         this.formDataString = newFormDataString;
    //
    //         // Stop page loading
    //         this.stopPageLoading();
    //       })
    //       .catch((error) => {
    //         this.handleError(error);
    //       });
    //   }
    // },

    // isPageValid: async function(pageNumber){
    //   let isValid = false;
    //   if(!this.$refs || !this.$refs.proposalForm || !this.formData){
    //     return isValid;
    //   }
    //
    //   let page = this.wizardPages.find(wp => {
    //     return wp.pageNumber === pageNumber;
    //   });
    //   if(!page) {
    //     console.log(`Invalid page number ${pageNumber}`);
    //     return isValid;
    //   }
    //
    //   let wizardPageId = page.id;
    //   let isDevelopment = process.env.NODE_ENV === 'development';
    //
    //   let formChildren = this.$refs.proposalForm.$children;
    //   if(Array.isArray(formChildren)) {
    //     let currentPageComponent = _.find(formChildren, child => {
    //       return child.$el && child.$el.id === wizardPageId;
    //     });
    //     if(!currentPageComponent) {
    //       if(isDevelopment) {
    //         console.log(`Could not find currentPageComponent for id ${wizardPageId}`);
    //       }
    //     }
    //     else {
    //       let currentPageRef = currentPageComponent.$refs[wizardPageId];
    //       if(!currentPageRef) {
    //         if(isDevelopment) {
    //           console.log(`Could not find currentPageRef for id ${wizardPageId}`);
    //         }
    //       }
    //       else {
    //         isValid = await currentPageRef.validate();
    //         if(!isValid && isDevelopment) {
    //           console.log(currentPageRef.errors);
    //         }
    //       }
    //     }
    //   }
    //
    //   return isValid;
    // },

    // submitForm: async function() {
    //   this.clearError();
    //
    //   // Validate the last page
    //   let isLastPageValid = await this.isPageValid(this.wizardObj.getStep());
    //
    //   // Validate the signature data
    //   this.formData.signatureData = this.$refs.wizardPage7.$refs.proposalFormSignaturePad.saveSignature();
    //
    //   // Stop the form submission if the last page and/or signature is not valid.
    //   if(!isLastPageValid || !this.formData.signatureData || this.formData.signatureData.isEmpty === true){
    //     this.setError('Please complete all fields on this page.');
    //     return;
    //   }
    //
    //   // Show page loading
    //   this.startPageLoading();
    //
    //   this.$apiService.post(`${this.serverUrl}/mpf/form/${this.formId}/submit`, {
    //     formData: this.formData,
    //     userId: this.userId
    //   })
    //     .then(() => {
    //       this.formData.formStatus = 'Active'; // This sets the form to read-only mode.
    //       this.stopPageLoading();
    //
    //       let message = null;
    //       if(this.isRenewalProposalForm) {
    //         message = `Thank you for your submission. We appreciate your business and will be in touch shortly.`;
    //       }
    //       else {
    //         message = `Thank you for requesting a quote. We appreciate your business and aim to respond to all quotes within
    //                  24 business hours, and provide indicative terms within 3 business days if we have the correct information.`;
    //       }
    //
    //       Swal.fire({
    //         titleText: "Form Submission Successful",
    //         html: `<p class="text-justify">${message}</p><br/>
    //                <span class="font-weight-bold">Please close this browser window.</span>`,
    //         icon: "success",
    //         width: '50rem',
    //         allowOutsideClick: false,
    //         allowEnterKey: false,
    //         allowEscapeKey: false,
    //         showConfirmButton: false,
    //         showCancelButton: false,
    //         showCloseButton: false
    //       });
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      if(process.env.NODE_ENV === 'development') {
        console.log(errorObj);
      }

      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
