<template>
<ValidationObserver ref="wizardPage5" id="wizardPage5" name="wizardPage5">
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <div class="mb-10 font-weight-bold text-dark" v-if="formData && referenceData">

      <!-- begin: Requirements of Insurance-->
      <div v-if="!isRenewal">
        <h3>REQUIREMENTS OF INSURANCE</h3>
        <b-card
          border-variant="primary"
          class="mb-5 mt-3"
          body-class="px-7 py-5"
        >
          <div class="form-group row mb-1">
            <div class="col-6 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Insurance Start Date<span class="text-danger">*</span>
                </strong>
              </label>
              <ValidationProvider rules="required" name="requirementsOfInsurance.insuranceStartDate" v-slot="{ errors }">
                <b-form-datepicker
                  id="requirementsOfInsurance-insuranceStartData"
                  class="form-control form-control-sm col-5"
                  v-model="formData.requirementsOfInsurance.insuranceStartDate"
                  name="requirementsOfInsurance.insuranceStartDate"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :min="today"
                  locale="en-AU"
                  @input="setInsuranceEndDate"
                  :disabled="isReadOnly"
                >
                </b-form-datepicker>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <b-form-text id="input-help-insuranceStartDate" class="font-italic">4pm local standard time</b-form-text>
            </div>
            <div class="col-6 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Insurance End Date<span class="text-danger">*</span>
                </strong>
              </label>
              <ValidationProvider rules="required" name="requirementsOfInsurance.insuranceEndDate" v-slot="{ errors }">
                <b-form-datepicker
                  id="requirementsOfInsurance-insuranceEndData" class="form-control form-control-sm col-5"
                  v-model="formData.requirementsOfInsurance.insuranceEndDate"
                  name="requirementsOfInsurance.insuranceEndDate"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :min="today"
                  :disabled="isReadOnly"
                  locale="en-AU"
                  v-if="render"
                >
                </b-form-datepicker>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <b-form-text id="input-help-insuranceEndDate" class="font-italic">4pm local standard time</b-form-text>
            </div>
          </div>

          <div class="form-group row mb-1">
            <div class="form-group col-4 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Preferred Excess<span class="text-danger">*</span>
                </strong>
              </label>
              <ValidationProvider rules="required" name="requirementsOfInsurance.requiredExcess" v-slot="{ errors }">
                <b-form-select v-model="formData.requirementsOfInsurance.requiredExcess" :options="availableExcesses"
                          size="sm" :disabled="isReadOnly" class="custom-select custom-select-md">
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group col-4 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Alternate Excess 1 (Optional)
                </strong>
              </label>
              <ValidationProvider rules="" name="requirementsOfInsurance.alternateExcess1" v-slot="{ errors }">
                <b-form-select v-model="formData.requirementsOfInsurance.alternateExcess1" :options="availableExcessesMinusSelected"
                               size="sm" :disabled="isReadOnly" class="custom-select custom-select-md">
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group col-4 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Alternate Excess 2 (Optional)
                </strong>
              </label>
              <ValidationProvider rules="" name="requirementsOfInsurance.alternateExcess2" v-slot="{ errors }">
                <b-form-select v-model="formData.requirementsOfInsurance.alternateExcess2" :options="availableExcessesMinusSelected"
                               size="sm" :disabled="isReadOnly" class="custom-select custom-select-md">
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
<!--          <div class="form-group row mb-1">-->
<!--            <div class="form-group col-12 mb-1" v-if="formData.requirementsOfInsurance.requiredExcess === 'Other'">-->
<!--              <label class="col-form-label text-right">-->
<!--                <strong>-->
<!--                  Other Excess - Form would be referred to the Underwriters for discussion and approval.-->
<!--                </strong>-->
<!--              </label>-->
<!--              <ValidationProvider rules="required" name="requirementsOfInsurance.detailsOfOtherExcess" v-slot="{ errors }">-->
<!--                <currency-input class="form-control form-control-sm" v-model="formData.requirementsOfInsurance.detailsOfOtherExcess" :disabled="isReadOnly"/>-->
<!--                <span class="text-danger">{{ errors[0] }}</span>-->
<!--              </ValidationProvider>-->
<!--            </div>-->
<!--          </div>-->
        </b-card>
      </div>
      <!-- end: Requirements of Insurance-->

<!--      &lt;!&ndash; begin: Finance &ndash;&gt;-->
<!--      <div v-if="!isRenewal">-->
<!--        <h3>FINANCE</h3>-->
<!--        <b-card-->
<!--          border-variant="primary"-->
<!--          class="mb-5 mt-3"-->
<!--          body-class="px-7 py-5"-->
<!--        >-->
<!--          <div class="form-group row mb-1">-->
<!--            <div class="form-group col-12 mb-1">-->
<!--              <label class="col-form-label text-right">-->
<!--                <strong>Are any of the vehicles subject to finance?<span v-if="!isUwMode" class="text-danger">*</span></strong>-->
<!--              </label>-->

<!--              <b-form-group class="mb-1">-->
<!--                <ValidationProvider :rules="{ required: !isUwMode }" name="finance.areVehiclesSubjectToFinance" v-slot="{ errors }">-->
<!--                  <b-form-radio-group-->
<!--                    id="finance-areVehiclesSubjectToFinance" v-model="formData.finance.areVehiclesSubjectToFinance"-->
<!--                    name="finance.areVehiclesSubjectToFinance" type="radio" :disabled="isReadOnly"-->
<!--                  >-->
<!--                    <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
<!--                    <b-form-radio type="radio" value="No">No</b-form-radio>-->
<!--                  </b-form-radio-group>-->
<!--                  <span class="text-danger">{{ errors[0] }}</span>-->
<!--              </ValidationProvider>-->
<!--              </b-form-group>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div v-if="formData.finance.areVehiclesSubjectToFinance === 'Yes'">-->
<!--            <div class="form-group row mb-1">-->
<!--              <div class="form-group col-4 mb-1">-->
<!--                <label class="col-form-label text-right">-->
<!--                  <strong>-->
<!--                    What % of the fleet is financed?<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--                  </strong>-->
<!--                </label>-->
<!--                <ValidationProvider :rules="{ required: !isUwMode }" name="finance.percentageOfFleetFinanced" v-slot="{ errors }">-->
<!--                  <b-form-select-->
<!--                    class="custom-select custom-select-md" v-model="formData.finance.percentageOfFleetFinanced"-->
<!--                    name="finance.percentageOfFleetFinanced" :disabled="isReadOnly"-->
<!--                  >-->
<!--                    <option value="1-10%">1-10%</option>-->
<!--                    <option value="11-20%">11-20%</option>-->
<!--                    <option value="21-30%">21-30%</option>-->
<!--                    <option value="31-40%">31-40%</option>-->
<!--                    <option value="41-50%">41-50%</option>-->
<!--                    <option value="51% or Over">51% or Over</option>-->
<!--                  </b-form-select>-->
<!--                  <span class="text-danger">{{ errors[0] }}</span>-->
<!--                </ValidationProvider>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="form-group row mb-1">-->
<!--              <div class="form-group col-7 mb-1">-->
<!--                <label class="col-form-label text-right">-->
<!--                  <strong>Type of Finance<span v-if="!isUwMode" class="text-danger">*</span></strong>-->
<!--                </label>-->

<!--                <b-form-group class="mb-1">-->
<!--                  <ValidationProvider :rules="{ required: !isUwMode }" name="finance.typeOfFinance" v-slot="{ errors }">-->
<!--                    <b-form-radio-group-->
<!--                      id="finance-typeOfFinance" v-model="formData.finance.typeOfFinance"-->
<!--                      name="finance.typeOfFinance" :disabled="isReadOnly"-->
<!--                    >-->
<!--                      <b-form-radio type="radio" value="Hire Purchase">Hire Purchase</b-form-radio>-->
<!--                      <b-form-radio type="radio" value="Financial Lease">Financial Lease</b-form-radio>-->
<!--                      <b-form-radio type="radio" value="Operating Lease">Operating Lease</b-form-radio>-->
<!--                      <b-form-radio type="radio" value="Other">Other</b-form-radio>-->
<!--                    </b-form-radio-group>-->
<!--                    <span class="text-danger">{{ errors[0] }}</span>-->
<!--                  </ValidationProvider>-->
<!--                </b-form-group>-->
<!--              </div>-->

<!--              <div class="form-group col-5 mb-1" v-if="formData.finance.typeOfFinance === 'Other'">-->
<!--                <label class="col-form-label text-right">-->
<!--                  <strong>-->
<!--                    Other type of Finance details<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--                  </strong>-->
<!--                </label>-->
<!--                <ValidationProvider :rules="{ required: !isUwMode, max: 255 }" name="finance.otherFinanceDetails" v-slot="{ errors }">-->
<!--                  <input class="form-control" type="text" v-model="formData.finance.otherFinanceDetails"-->
<!--                    placeholder="Please specify the type of Finance" :disabled="isReadOnly"-->
<!--                  />-->
<!--                  <span class="text-danger">{{ errors[0] }}</span>-->
<!--                </ValidationProvider>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="form-group row mb-1">-->
<!--              <div class="form-group col-7 mb-1">-->
<!--                <label class="col-form-label text-right">-->
<!--                  <strong>-->
<!--                    How many months are the vehicles financed?<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--                  </strong>-->
<!--                </label>-->

<!--                <b-form-group class="mb-1">-->
<!--                  <ValidationProvider :rules="{ required: !isUwMode }" name="finance.noOfMonthsVehiclesFinanced" v-slot="{ errors }">-->
<!--                    <b-form-radio-group-->
<!--                      id="requirementsOfInsurance-typeOfFinance" v-model="formData.finance.noOfMonthsVehiclesFinanced"-->
<!--                      name="finance.noOfMonthsVehiclesFinanced" type="radio" :disabled="isReadOnly"-->
<!--                    >-->
<!--                      <b-form-radio type="radio" value="12">12</b-form-radio>-->
<!--                      <b-form-radio type="radio" value="24">24</b-form-radio>-->
<!--                      <b-form-radio type="radio" value="36">36</b-form-radio>-->
<!--                      <b-form-radio type="radio" value="Other">Other</b-form-radio>-->
<!--                    </b-form-radio-group>-->
<!--                    <span class="text-danger">{{ errors[0] }}</span>-->
<!--                  </ValidationProvider>-->
<!--                </b-form-group>-->
<!--              </div>-->

<!--              <div class="form-group col-5 mb-1" v-if="formData.finance.noOfMonthsVehiclesFinanced === 'Other'">-->
<!--                <label class="col-form-label text-right">-->
<!--                  <strong>Other number of months<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--                  </strong>-->
<!--                </label>-->
<!--                <ValidationProvider :rules="{ required: !isUwMode }" name="finance.otherNumberOfMonthsVehiclesFinanced" v-slot="{ errors }">-->
<!--                  <input class="form-control" type="number" v-model="formData.finance.otherNumberOfMonthsVehiclesFinanced"-->
<!--                         placeholder="Please specify the number of months" :disabled="isReadOnly"/>-->
<!--                  <span class="text-danger">{{ errors[0] }}</span>-->
<!--                </ValidationProvider>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-card>-->
<!--      </div>-->
<!--      &lt;!&ndash; end: Finance &ndash;&gt;-->

      <!-- begin: Current Insurance Details-->
      <div v-if="!isRenewal && formData.businessType === 'established'">
        <current-insurance-details :form-data="formData" :is-uw-mode="isUwMode" :is-read-only="isReadOnly"/>
      </div>
      <!-- end: Current Insurance Details-->

      <div v-if="!isRenewal">
        <!-- begin: Insurance Claims & Loss History-->
        <h3>INSURANCE CLAIMS & LOSS HISTORY</h3>
        <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
          <p class="text-left">
            <strong>If the Business has been trading for any period, losses / claims must be attached since the start of trading.</strong><br>
          </p>
          <div class="form-group row mb-1">
            <div class="col-12 mb-1">
              <label class="col-form-label text-left">
                <strong>
                  Have you had any accidents, claims or losses during the past five (5) years (whether to blame or not) involving any vehicles owned or insured by you?<span class="text-danger">*</span>
                </strong>
              </label>
              <b-form-group class="mb-1">
                <ValidationProvider rules="required" name="insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses" v-slot="{ errors }">
                  <b-form-radio-group
                      id="insuranceClaimsAndLossHistory-hadAnyAccidentsClaimsLosses"
                      name="insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses"
                      type="radio"
                      v-model="formData.insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses"
                      :disabled="isReadOnly"
                  >
                    <b-form-radio type="radio" value="Yes">Yes</b-form-radio>
                    <b-form-radio type="radio" value="No">No</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>

          <div class="mt-2" v-if="formData.insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses === 'Yes'">

            <div v-if="Array.isArray(formData.insuranceClaimsAndLossHistory.claimsHistory) && formData.insuranceClaimsAndLossHistory.claimsHistory.length>0" class="mb-5 mt-3">
              <strong>
                If you can provide claims summary below, it will speed up the quoting process.<br /><br />
                <p class="text-center"><u>CLAIMS HISTORY SUMMARY</u></p>
              </strong>

              <table class="table table-sm table-bordered mt-3 mb-5">
                <thead>
                <th width="20%" class="text-center">Year</th>
                <th width="20%" class="text-center">Total Number of Claims</th>
                <th width="20%" class="text-center">Total Net Incurred</th>
                <th width="20%" class="text-center">Number of Claims due to Large Losses (> $20,000)</th>
                <th width="20%" class="text-center">Number of Claims due to Natural Perils</th>
                </thead>

                <tr v-for="(claimsPeriod, index) in formData.insuranceClaimsAndLossHistory.claimsHistory" :key="index">
                  <td class="text-center pt-3">
                    {{ claimsPeriod.year }}
                  </td>
                  <td>
                    <ValidationProvider rules="" name="claimsPeriod.noOfClaims"  v-slot="{ errors }">
                      <input type="number" class="form-control" v-model.number="claimsPeriod.noOfClaims" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider rules="" name="claimsPeriod.netIncurred" v-slot="{ errors }">
                      <currency-input class="form-control" v-model="claimsPeriod.netIncurred" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider rules="" name="claimsPeriod.noOfClaimsLargeLosses"  v-slot="{ errors }">
                      <input type="number" class="form-control" v-model.number="claimsPeriod.noOfClaimsLargeLosses" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider rules="" name="claimsPeriod.noOfClaimsNaturalPerils"  v-slot="{ errors }">
                      <input type="number" class="form-control" v-model.number="claimsPeriod.noOfClaimsNaturalPerils" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
              </table>
            </div>

            <strong>
              <p class="text-center">
                <u>PLEASE PROVIDE THE INSURERS WRITTEN CLAIMS EXPERIENCE</u>
              </p>
            </strong>
          </div>
          <div class="mt-2" v-if="formData.insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses === 'No'">
            <p class="text-left">
              <strong>
                If you have had no accidents, claims or losses during your
                trading period, (whether to blame or not) involving any vehicles
                owned or insured by you, please attach a letter confirming this
                from your current Insurance provider.<br><br>
                If you have not held a policy and have had no claims, loss, damage, or liability incidents,
                please provide a Statutory Declaration signed under the Oaths Act stating that the business,
                proposed insured, directors, have not sustained any motor vehicle loss, damage, or liability
                incidents whether insured or not insured in the last five (5) years.
              </strong>
            </p>
          </div>
          <div v-if="formData.insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses">
            <label class="col-form-label text-left" v-if="formData.insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses === 'Yes'">
              <strong>Please upload the Claims History Document(s)<span class="text-danger">*</span></strong>
            </label>
            <label class="col-form-label text-left" v-if="formData.insuranceClaimsAndLossHistory.hadAnyAccidentsClaimsLosses === 'No'">
              <strong>Please upload the Claims History Document(s) or Statutory Declaration<span class="text-danger">*</span></strong>
            </label>
<!--            <file-upload :file-key="'claimsHistoryDocument'"-->
<!--                         :form-data="formData"-->
<!--                         :is-read-only="isReadOnly">-->
<!--            </file-upload>-->
            <files-upload
                :upload-key="'claimsHistoryDocument'" :uploaded-files="formData.formUploads.claimsHistoryDocument"
                :upload-in-progress="uploadProgress.claimsHistoryDocument" allow-multiple
                :is-read-only="isReadOnly" v-on="$listeners">
            </files-upload>
          </div>
        </b-card>
        <!-- end: Insurance Claims & Loss History-->
      </div>
    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import CurrentInsuranceDetails from "@/view/pages/wizard/motor-proposal-form/components/CurrentInsuranceDetails.vue";
// import FileUpload from "@/view/pages/wizard/motor-proposal-form/components/FileUpload.vue";
import FilesUpload from "@/view/pages/wizard/motor-proposal-form/components/FilesUpload.vue";

export default {
  name: "WizardPage5",

  components: {
    CurrentInsuranceDetails,
    // FileUpload,
    FilesUpload
  },

  props: {
    formData: Object,
    referenceData: Object,
    uploadProgress: Object,
    isReadOnly: Boolean,
    isRenewal: Boolean,
    isUwMode: Boolean
  },

  computed: {
    today: function() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },

    availableExcesses: function() {
      const excesses = [
        { text: '', value: ''},
        { text: '$2,000', value: '2000'},
        { text: '$5,000', value: '5000'},
        { text: '$7,500', value: '7500'},
        { text: '$10,000', value: '10000'},
        { text: '$15,000', value: '15000'},
        { text: '$20,000', value: '20000'}
      ];

      if(this.formData?.brand === 'Fuse') {
        excesses.splice(1, 0, { text: '$1,000', value: '1000'});
      }
      return excesses;
    },

    availableExcessesMinusSelected: function () {
      const excesses = this.availableExcesses;
      if(this.formData.requirementsOfInsurance.requiredExcess) {
        return _.filter(excesses, ex => {
          return ex.value !== this.formData.requirementsOfInsurance.requiredExcess;
        });
      }
      return excesses;
    }
  },

  methods: {
    setInsuranceEndDate: function () {
      if (!this.isReadOnly) {
        let insuranceStartDate = _.get(this.formData, 'requirementsOfInsurance.insuranceStartDate');
        if (insuranceStartDate) {
          let insuranceStartDateMoment = moment.tz(insuranceStartDate, 'YYYY-MM-DD', 'Australia/Sydney');
          let insuranceEndDate = insuranceStartDateMoment.add(1, 'years').format('YYYY-MM-DD');
          this.formData.requirementsOfInsurance.insuranceEndDate = insuranceEndDate;

          // Horrible hack to force b-form-datepicker to re-render to refresh the date on the UI
          this.render = false;
          this.$nextTick(function () {
            this.render = true;
          })
        }
      }
    }
  },

  data() {
    return {
      render: true,
    };
  }

};
</script>
