<template>
<ValidationObserver ref="wizardPage7" id="wizardPage7" name="wizardPage7">
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <div class="mb-10 font-weight-bold text-dark" v-if="formData && referenceData">

<!--      &lt;!&ndash; begin: Additional Information&ndash;&gt;-->
<!--      <h3>ADDITIONAL INFORMATION</h3>-->
<!--      <b-card border-variant="primary"  class="mb-5 mt-3" body-class="px-7 py-5">-->
<!--        <p class="text-left">-->
<!--          <strong>-->
<!--            If insufficient space is available on this proposal with respect to-->
<!--            any questions contained, then please upload or enter below-->
<!--            additional information, noting relevant section and ensuring to sign-->
<!--            and date any such attachments.-->
<!--          </strong>-->
<!--        </p>-->
<!--        <div class="form-group row mb-3">-->
<!--          <div class="col-12">-->
<!--            <label class="col-form-label text-left">-->
<!--              <strong> Additional Information </strong>-->
<!--            </label>-->
<!--            <ValidationProvider rules="max:2000" name="additionalInformation.detailsOfAdditionalInformation" v-slot="{ errors }">-->
<!--              <b-form-textarea-->
<!--                id="additionalInformation-detailsOfAdditionalInformation"-->
<!--                name="additionalInformation.detailsOfAdditionalInformation"-->
<!--                v-model="formData.additionalInformation.detailsOfAdditionalInformation"-->
<!--                type="text"-->
<!--                rows="4"-->
<!--                :disabled="isReadOnly"-->
<!--              ></b-form-textarea>-->
<!--              <span class="text-danger">{{ errors[0] }}</span>-->
<!--            </ValidationProvider>-->
<!--          </div>-->
<!--        </div>-->
<!--        <file-upload :file-key="'additionalInformationDocument'"-->
<!--                     :form-data="formData"-->
<!--                     :is-read-only="isReadOnly"-->
<!--                     :is-optional="true"-->
<!--        ></file-upload>-->
<!--      </b-card>-->
<!--      &lt;!&ndash; end: Additional Information&ndash;&gt;-->

      <!-- begin: Important Notice-->
      <h3>IMPORTANT NOTICE</h3>
      <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
        <p class="text-left mb-0">
          <strong> Cooling Off </strong><br />
          <label class="text-justify">
            If you are not completely satisfied with Your Policy You May cancel it by notifying Us in writing within
            30 days of cover having commenced. You will receive a refund of the amount You have paid unless something
            has occurred for which a claim may become payable under the Policy.
          </label><br /><br />
          <strong>Confirming Transactions</strong><br />
          <label class="text-justify">
            You may contact Us or Your Adviser, in writing (which is always required if You are advising cancellation)
            or by phone, to confirm any transaction under Your Policy. Any Transaction will be documented by
            Us as quickly as possible.
          </label><br /><br />
          <strong>Code of Practice</strong><br />
          <label class="text-justify">
            A self-regulatory Code of Practice exists for the general insurance industry, designed to raise overall
            standards. CGU has adopted the Code, details of which can be obtained from Your insurance broker or
            any CGU office.
          </label><br /><br />
          <strong>Complaints</strong><br />
          <label class="text-justify">
            Internal and External Complaints Procedure If You do not agree with any decision We make in relation to
            Your insurance, please write to Us stating what You disagree with and why. We will then either resolve or
            attempt to resolve Your complaint immediately or refer the matter to Our Internal Dispute Resolution
            Committee (IDRC). If You are not satisfied with a claim decision by the IDRC, the matter may be referred
            to an independent alternative dispute resolution body, the Australian Financial Complaints Authority (AFCA)
            provided it falls within their jurisdiction.
          </label>
        </p>
      </b-card>
      <!-- end: Important Notice-->

      <!-- begin: Privacy-->
      <h3>PRIVACY</h3>
      <b-card
        border-variant="primary"
        class="mb-5 mt-3"
        body-class="px-7 py-5"
      >
        <p class="text-left mb-0 text-justify">
          {{ brandName }} respects your privacy and complies with the Privacy Act and the National Principles. A copy of our
          Privacy policy is available upon request or online at <a href="https://www.cgu.com.au/" target="_blank"><u>www.cgu.com.au</u></a>
        </p>
      </b-card>
      <!-- end: Privacy-->

      <!-- begin: Duty of disclosure-->
      <h3>DUTY OF DISCLOSURE</h3>
      <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
        <p class="text-left text-justify">
          Before you enter into a contract of general insurance with an insurer, you have a duty at law to disclose
          to the insurer anything that you could reasonably be expected to know is relevant to the insurer's decision
          whether to accept the risk of insurance and, if so, on what terms.<br /><br />
          You have the same duty to disclosure those matters to the insurer before you renew, extend, vary or reinstate
          a contract of general insurance.<br /><br />
          If you fail to comply with your duty of disclosure the insurer may be entitled to reduce its liability under
          the contract in respect of a claim or may cancel the contract.<br /><br />
          If your non-disclosure is fraudulent, the insurer may also have the option of avoiding the contract from its beginning.
        </p>
      </b-card>
      <!-- end: Duty of disclosure -->

      <!-- begin: Declaration -->
      <h3>DECLARATION</h3>
      <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
        <div class="form-group row mb-1">
          <div class="col-12 mb-1">
            <label class="col-form-label text-left">
              <strong>
                Have you or any partner, principal or director ever had insurance declined, cancelled, renewal
                refused or special conditions imposed by an insurer?<span class="text-danger">*</span>
              </strong>
            </label>
            <b-form-group class="mb-1">
              <ValidationProvider rules="required" name="declarations.hadInsuranceDeclined" v-slot="{ errors }">
                <b-form-radio-group
                  id="declarations-hadInsuranceDeclined" name="declarations.hadInsuranceDeclined" type="radio"
                  v-model="formData.declarations.hadInsuranceDeclined" :disabled="isReadOnly"
                >
                  <b-form-radio type="radio" value="yes">Yes</b-form-radio>
                  <b-form-radio type="radio" value="no">No</b-form-radio>
                </b-form-radio-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </div>
        </div>
        <div class="form-group row mb-3" v-if="formData.declarations.hadInsuranceDeclined === 'yes'">
          <div class="col-12">
            <label class="col-form-label text-left">
              <strong>
                Please provide details.<span class="text-danger">*</span>
              </strong>
            </label>
            <ValidationProvider rules="required|max:2000" name="declarations.hadInsuranceDeclinedDetails" v-slot="{ errors }">
              <b-form-textarea
                id="declarations-detailsOfInsuranceDeclined" name="declarations.detailsOfInsuranceDeclined"
                v-model="formData.declarations.detailsOfInsuranceDeclined" rows="4" :disabled="isReadOnly"
              ></b-form-textarea>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="form-group row mb-1">
          <div class="col-12 mb-1">
            <label class="col-form-label text-left">
              <strong>
                Have you or any partner, principal or director ever been, or declared bankrupt in the last 5 years?<span class="text-danger">*</span>
              </strong>
            </label>
            <b-form-group class="mb-1">
              <ValidationProvider rules="required" name="declarations.bankruptcy" v-slot="{ errors }">
                <b-form-radio-group id="declarations-bankruptcy" name="declarations.bankruptcy" type="radio"
                    v-model="formData.declarations.bankruptcy" :disabled="isReadOnly"
                >
                  <b-form-radio type="radio" value="yes">Yes</b-form-radio>
                  <b-form-radio type="radio" value="no">No</b-form-radio>
                </b-form-radio-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </div>
        </div>
        <div class="form-group row mb-3" v-if="formData.declarations.bankruptcy === 'yes'">
          <div class="col-12">
            <label class="col-form-label text-left">
              <strong>
                Please provide details.<span class="text-danger">*</span>
              </strong>
            </label>
            <ValidationProvider rules="required|max:2000" name="declarations.bankruptcyDetails" v-slot="{ errors }">
              <b-form-textarea
                  id="declarations-bankruptcyDetails" name="declarations.bankruptcyDetails"
                  v-model="formData.declarations.bankruptcyDetails" rows="4" :disabled="isReadOnly"
              ></b-form-textarea>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <!--        <div class="form-group row mb-1">-->
<!--          <div class="col-12 mb-1">-->
<!--            <label class="col-form-label text-right">-->
<!--              <strong>-->
<!--                Has any insurer required an increase in premium or imposed-->
<!--                special conditions?<span class="text-danger">*</span>-->
<!--              </strong>-->
<!--            </label>-->
<!--            <b-form-group class="mb-1">-->
<!--              <ValidationProvider rules="required" name="declarations.hasPremiunIncreased" v-slot="{ errors }">-->
<!--                <b-form-radio-group-->
<!--                  id="declarations-hasPremiunIncreased"-->
<!--                  v-model="formData.declarations.hasPremiunIncreased"-->
<!--                  name="declarations.hasPremiunIncreased"-->
<!--                  type="radio"-->
<!--                  :disabled="isReadOnly"-->
<!--                >-->
<!--                  <b-form-radio type="radio" value="yes">Yes</b-form-radio>-->
<!--                  <b-form-radio type="radio" value="no">No</b-form-radio>-->
<!--                </b-form-radio-group>-->
<!--                <span class="text-danger">{{ errors[0] }}</span>-->
<!--              </ValidationProvider>-->
<!--            </b-form-group>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div-->
<!--          class="form-group row mb-4"-->
<!--          v-if="formData.declarations.hasPremiunIncreased === 'yes'"-->
<!--        >-->
<!--          <div class="col-12">-->
<!--            <label class="col-form-label text-left">-->
<!--              <strong>-->
<!--                Please provide details.<span class="text-danger">*</span>-->
<!--              </strong>-->
<!--            </label>-->
<!--            <ValidationProvider rules="required|max:2000" name="declarations.hasPremiunIncreased" v-slot="{ errors }">-->
<!--              <b-form-textarea-->
<!--                id="declarations-detailsOfIcreaseInPremiun"-->
<!--                v-model="formData.declarations.detailsOfIcreaseInPremiun"-->
<!--                rows="4"-->
<!--                :disabled="isReadOnly"-->
<!--              ></b-form-textarea>-->
<!--              <span class="text-danger">{{ errors[0] }}</span>-->
<!--            </ValidationProvider>-->
<!--          </div>-->
<!--        </div>-->
        <p class="text-left mt-3">
          <label class="text-justify">
            I/We hereby declare that we have read the privacy statement above and consent to the collection of the
            above information by {{ brandName }} and/or CGU.
          </label><br /><br />
          <label class="text-justify">
            I/We hereby declare and warrant that I/We have read this proposal and that the answers above are in every
            way true and correct and that I/We have not withheld any material information. I/We also agree at the
            request of {{ brandName }} or CGU to obtain from the relevant authority or Government department a complete
            and up to date record of offences.
          </label><br /><br />
          <label class="text-justify">
            I/We understand that no insurance for any vehicle is in force until such time this proposal is received and
            accepted by {{ brandName }}. This document must be signed by an authorised agent and/or proponent.
          </label><br/><br/>

          <label class="text-justify">
            In an effort to improve our service, save time and conserve paper, where possible, we send insurance
            documents electronically. By submitting this form, you are consenting to delivery of your Insurance
            Policies, Product Disclosure Statements, our Financial Services Guide and other disclosure documents by
            email. If at any stage you no longer want to receive documentation from us electronically, please phone us on
            <a :href="`tel:${brandPhoneNumber}`">{{ brandPhoneNumber }}</a> or email us at
            <a :href="`mailto:${brandEmail}`">{{ brandEmail }}</a> to notify us of your preferences.
          </label>
        </p>
        <b-card border-variant="dark" bg-variant="light" class="mb-5 mt-3" body-class="px-7 py-5">
          <div class="form-group row mb-1">
            <div class="col-12">
              <label class="col-form-label text-right">
                <strong>
                  Full name of person completing this proposal<span class="text-danger">*</span>
                </strong>
              </label>
            </div>
          </div>
          <div class="form-group row mb-1">
            <div class="col-4">
              <ValidationProvider rules="required|max:255" name="personCompletingForm.firstName" v-slot="{ errors }">
                <input class="form-control" type="text" placeholder="First Name"
                  v-model="formData.personCompletingForm.firstName" :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-4">
              <ValidationProvider rules="required|max:255" name="personCompletingForm.lastName" v-slot="{ errors }">
                <input
                  class="form-control" placeholder="Last Name" type="text"
                  v-model="formData.personCompletingForm.lastName" :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-1">
            <div class="col-4 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Title / Company Position<span class="text-danger">*</span>
                </strong>
              </label>
              <ValidationProvider rules="required" name="personCompletingForm.title" v-slot="{ errors }">
                <input class="form-control" v-model="formData.personCompletingForm.title" type="text" :disabled="isReadOnly"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-1">
            <div class="col-6 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Signature<span class="text-danger">*</span>
                </strong>
              </label>

              <div v-if="!isReadOnly">
                <!-- Signature Pad-->
                <VueSignaturePad width="500px" height="200px" :customStyle="signaturePadCustomStyle"
                                 ref="proposalFormSignaturePad" :options="{ onBegin }" />
                <div v-if="formData.signatureData && formData.signatureData.isEmpty === true">
                  <span class="text-danger">Signature cannot be empty</span>
                </div>
                <div>
                  <button class="btn btn-primary btn-sm m-2" @click.prevent="clearSignature()" v-if="!isReadOnly">
                    Clear Signature
                  </button>
                </div>
              </div>
              <div v-if="isReadOnly && hasSignatureData">
                <img :src="formData.signatureData.data" width="500px" height="200px" :class="signaturePadCustomStyle"/>
              </div>
            </div>
          </div>
       
          <div class="form-group row mb-0">
            <div class="col-4" >
              <label class="col-form-label text-right pb-0">
                <strong> Proposal Date </strong><br />
              </label>
            </div>
          </div>
          <div class="form-group row mb-0">
            <div class="col-4">
              <label class="col-form-label text-right pt-0 pb-0">
                {{ getProposalDate }}
              </label>
            </div>
          </div>
        </b-card>
      </b-card>
      <!-- end: Declaration-->

    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import moment from "moment-timezone";
// import FileUpload from "@/view/pages/wizard/motor-proposal-form/components/FileUpload.vue";
import _ from "lodash";

export default {
  name: "WizardPage7",

  components: {
    // FileUpload
  },

  props: {
    formData: Object,
    referenceData: Object,
    isReadOnly: Boolean,
    isRenewal: Boolean
  },

  data() {
    return {
      currentTimeStamp: null,

      signaturePadCustomStyle: {
        border :"gray 1px solid"
      }
    };
  },

  computed: {
    getProposalDate: function () {
      if(this.formData && this.formData.proposalDate) {
        return moment.tz(this.formData.proposalDate, 'YYYY-MM-DD', 'Australia/Sydney').format("DD/MM/YYYY");
      }
      return moment.tz("Australia/Sydney").format("DD/MM/YYYY");
    },

    brandName: function () {
      const brand = _.toUpper(_.get(this.formData, 'brand'));
      let brandName = null;
      switch (brand) {
        case 'RENTSURE': brandName = 'Rentsure'; break;
        case 'CRI': brandName = 'Car Rental Insurance'; break;
        case 'FUSE': brandName = 'Fuse Fleet Insurance'; break;
      }
      return brandName;
    },

    brandPhoneNumber: function() {
      let brand = _.toUpper(_.get(this.formData, 'brand'));
      let brandPhone = null;
      switch (brand) {
        case 'RENTSURE': brandPhone = '1800 355 646'; break;
        case 'CRI': brandPhone = '1300 675 050'; break;
        case 'FUSE': brandPhone = '1300 387 338'; break;
      }
      return brandPhone;
    },

    brandEmail: function() {
      let brand = _.toUpper(_.get(this.formData, 'brand'));
      let bEmail = null;
      switch (brand) {
        case 'RENTSURE': bEmail = 'insurance@rentsure.com.au'; break;
        case 'CRI': bEmail = 'insurance@crinsurance.com.au'; break;
        case 'FUSE': bEmail = 'insurance@fusefleet.com.au'; break;
      }
      return bEmail;
    },

    hasSignatureData: function() {
      return this.formData && this.formData.signatureData && this.formData.signatureData.data
          && String(this.formData.signatureData.data).startsWith('data:image/png;base64');
    }
  },

  methods: {

    onBegin: function() {
      this.$refs.proposalFormSignaturePad.resizeCanvas();
    },

    clearSignature: function() {
      this.$refs.proposalFormSignaturePad.clearSignature();
    }
  },
};
</script>
