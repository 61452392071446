<template>
<ValidationObserver ref="brokerageDetails">
  <form>
  <div class="form-group mt-3">
   
    <h3>BROKERAGE DETAILS</h3>
    <p class="font-italic font-weight-bold">AFS License Holder</p>

    <b-card
      border-variant="primary"
      class="mb-5 mt-3"
      body-class="px-7 py-5"
    >
      <div class="form-group row mb-0">
        <div class="col-12">
          <label class="col-form-label text-left font-weight-bold font-size-h6">Search for a Broker by Name or AFSL Number</label>
          <v-autocomplete
              class="mt-0 pt-0 mb-0" color="white"
              :items="afslEntitiesArray"
              v-model="selectedAfslEntity"
              :search-input.sync="searchStringAfsl"
              @change="setSelectedAfslEntity"
              :item-text="getAfslEntityItemText"
              label="Start typing Name or AFSL Number (minimum 3 characters)"
              return-object
              :disabled="isReadOnly"
              clearable
          ></v-autocomplete>
        </div>
      </div>

      <div class="form-group row mb-1">
        <div class="col-6">
          <label class="col-form-label text-left">
            Name of Insurance Broking Company
            <span class="text-danger">*</span>
          </label>
          <ValidationProvider rules="required|max:255" name="brokerageDetails.brokerCompany.name" v-slot="{ errors }">
            <input type="text" class="form-control" v-model="brokerageDetails.brokerCompany.name" maxlength="255" disabled/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-3">
          <label class="col-form-label text-left">AFSL<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|numeric|max:10" name="brokerageDetails.brokerCompany.AFSL" v-slot="{ errors }">
            <input type="text" class="form-control" v-model="brokerageDetails.brokerCompany.AFSL" maxlength="10" disabled/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-3">
          <label class="col-form-label text-left">Phone<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|max:15" name="brokerageDetails.brokerCompany.phone" v-slot="{ errors }">
            <input type="text" class="form-control" v-model="brokerageDetails.brokerCompany.phone" maxlength="15" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
           </ValidationProvider>
        </div>
      </div>
      <div class="form-group row mb-0 mt-5">
        <div class="col-12">
          <address-details :address-entity="brokerageDetails.brokerCompany.addressDetails"
                           :mandatory="true" :is-read-only="isReadOnly"></address-details>
        </div>
      </div>
    </b-card>

    <h3>BROKER CONTACT DETAILS</h3>
    <b-card
      border-variant="primary"
      class="mb-5 mt-3"
      body-class="px-7 py-5"
    >
      <div class="form-group row mb-1">
        <div class="col-12">
          <b-form-checkbox size="lg" v-model="brokerageDetails.brokerContactDetails.authorisedRepresentative" :disabled="isReadOnly">
            <strong>
              <div class="mt-1">
                Select if you are an Authorised Representative of the above AFSL holder?
              </div>
            </strong>
          </b-form-checkbox>
        </div>
      </div>

      <div class="form-group row mb-1" v-if="brokerageDetails.brokerContactDetails.authorisedRepresentative">

        <div class="col-12">
          <label class="col-form-label text-left font-weight-bold font-size-h6">Search for an Authorised Representative by Name or AR Number</label>
          <v-autocomplete
              class="mt-0 pt-0 mb-0" color="white"
              :items="arEntitiesArray"
              v-model="selectedArEntity"
              @change="setSelectedArEntity"
              :item-text="getArEntityItemText"
              label="Start typing Name or AR Number (minimum 3 characters)"
              return-object
              :disabled="isReadOnly"
              clearable
          ></v-autocomplete>
        </div>

        <div class="col-6">
          <label class="col-form-label text-left">
            Authorised Representative Company Name<span class="text-danger">*</span>
          </label>
          <ValidationProvider rules="required|max:255" name="brokerageDetails.brokerContactDetails.arCompanyName" v-slot="{ errors }">
            <input class="form-control" type="text" v-model="brokerageDetails.brokerContactDetails.arCompanyName" maxlength="255" disabled/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-6">
          <label class="col-form-label text-left">Authorised Representative Number<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|max:255" name="brokerageDetails.brokerContactDetails.arNo" v-slot="{ errors }">
            <input class="form-control text-uppercase" v-model="brokerageDetails.brokerContactDetails.arNo" maxlength="255" disabled/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group row mb-1">
        <div class="col-6">
          <label class="col-form-label text-left">Broker First Name<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|max:255" name="brokerageDetails.brokerContactDetails.firstName" v-slot="{ errors }">
            <input type="text" class="form-control" v-model="brokerageDetails.brokerContactDetails.firstName" maxlength="255"
                   @input="brokerContactUpdated" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-6">
          <label class="col-form-label text-left">Broker Last Name<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|max:255" name="brokerageDetails.brokerContactDetails.lastName" v-slot="{ errors }">
            <input class="form-control" v-model="brokerageDetails.brokerContactDetails.lastName" maxlength="255"
                   @input="brokerContactUpdated" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div class="form-group row mb-1">
        <div class="col-6">
          <label class="col-form-label text-left">Broker Title / Company Position<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|max:255" name="brokerageDetails.brokerContactDetails.title" v-slot="{ errors }">
            <input class="form-control" v-model="brokerageDetails.brokerContactDetails.title" maxlength="255"
                   @input="brokerContactUpdated" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-6">
          <label class="col-form-label text-left">Broker Email<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|email|max:100" name="brokerageDetails.brokerContactDetails.email" v-slot="{ errors }">
            <input type="email" class="form-control" v-model="brokerageDetails.brokerContactDetails.email" maxlength="100"
                   @input="brokerContactUpdated" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="col-6">
          <label class="col-form-label text-left">Broker Phone<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|max:15" name="brokerageDetails.brokerContactDetails.phone" v-slot="{ errors }">
            <input class="form-control" v-model="brokerageDetails.brokerContactDetails.phone" maxlength="15"
                   @input="brokerContactUpdated" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-6">
          <label class="col-form-label text-left">Broker Mobile<span class="text-danger">*</span></label>
          <ValidationProvider rules="required|max:15" name="brokerageDetails.brokerContactDetails.mobile" v-slot="{ errors }">
            <input type="text" class="form-control" v-model="brokerageDetails.brokerContactDetails.mobile" maxlength="15"
                   @input="brokerContactUpdated" :disabled="isReadOnly"/>
            <span id="error" class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group row mb-1">
        <div class="col-12">
          <label class="col-form-label text-left"><strong>Is your address the same as AFS License Holder above?</strong></label>

          <b-form-group class="mb-1">
            <b-form-radio-group v-model="brokerageDetails.brokerContactDetails.isAddressSameAsBrokerCompany"
                                @change="setBrokerContactAddress" :disabled="isReadOnly">
              <b-form-radio value="sameAddress">Yes</b-form-radio>
              <b-form-radio value="differentAddress">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <p class="font-italic">
            If not an Authorised Representative or different branch
          </p>
        </div>
      </div>

      <div v-if="brokerageDetails.brokerContactDetails.isAddressSameAsBrokerCompany === 'differentAddress'">
        <address-details :address-entity="brokerageDetails.brokerContactDetails.addressDetails"
                         :mandatory="true" :is-read-only="isReadOnly"></address-details>
      </div>
    </b-card>
  </div>
  </form>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import AddressDetails from "@/view/pages/wizard/components/AddressDetails.vue";

export default {
  name: "BrokerageDetails",

  components: {
    AddressDetails
  },

  props: {
    brokerageDetails: Object,
    isReadOnly: Boolean
  },
 
  data() {
    return {
      selectedAfslEntity: null,
      searchStringAfsl: null,
      afslEntitiesArray: [],

      selectedArEntity: null,
      arEntitiesArray: []
    };
  },

  mounted() {
    // Get all ARs for the selected broker
    this.getARs();
  },

  watch: {
    searchStringAfsl (searchString) {
      if (!searchString) {
        searchString = "";
      }
      if(searchString.length > 2) {
        this.searchAfsl(searchString);
      }
    }
  },

  methods: {
    getAfslEntityItemText: function (item) {
      if(item) {
        return `${item.entityName} - AFSL ${item.afsl}`;
      }
      return '';
    },

    searchAfsl: function (searchString) {
      if(this.isReadOnly) {
        return;
      }

      const serverUrl = this.$apiService.getServerUrl();
      this.$apiService.get(`${serverUrl}/mpf/afsl/search`,
          { params: {searchString: searchString}}
      )
        .then(({ data }) => {
          this.afslEntitiesArray = data.result;
        })
        .catch((error) => {
          console.log("UI Error in searchAfsl:", error);
        });
    },

    setSelectedAfslEntity: function () {
      if(this.selectedAfslEntity) {
        this.$set(this.brokerageDetails.brokerCompany, 'name', this.selectedAfslEntity.entityName);
        this.$set(this.brokerageDetails.brokerCompany, 'AFSL', this.selectedAfslEntity.afsl);
        this.selectedAfslEntity = null;

        // Clear AR selection
        this.$set(this.brokerageDetails.brokerContactDetails, 'arCompanyName', null);
        this.$set(this.brokerageDetails.brokerContactDetails, 'arNo', null);
        this.selectedArEntity = null;

        // Get all ARs for the selected broker
        this.getARs();
      }
    },

    getArEntityItemText: function (item) {
      if(item) {
        return `${item.arName} - AR Number ${item.arNumber}`;
      }
      return '';
    },

    getARs: function () {
      if(this.isReadOnly) {
        return;
      }

      const afsl = _.get(this.brokerageDetails, 'brokerCompany.AFSL');
      if(afsl) {
        const serverUrl = this.$apiService.getServerUrl();
        this.$apiService.get(`${serverUrl}/mpf/afsl/${afsl}/ars`)
            .then(({ data }) => {
              this.arEntitiesArray = data.result;
            })
            .catch((error) => {
              console.log("UI Error in searchAr:", error);
            });
      }
    },

    setSelectedArEntity: function () {
      if(this.selectedArEntity) {
        this.$set(this.brokerageDetails.brokerContactDetails, 'arCompanyName', this.selectedArEntity.arName);
        this.$set(this.brokerageDetails.brokerContactDetails, 'arNo', this.selectedArEntity.arNumber);
        this.selectedArEntity = null;
      }
    },

    brokerContactUpdated: function() {
      this.$emit("brokerContactUpdated");
    },

    setBrokerContactAddress: function () {
      if(this.brokerageDetails.brokerContactDetails.isAddressSameAsBrokerCompany === 'sameAddress') {
        this.$set(this.brokerageDetails.brokerContactDetails, 'addressDetails', _.cloneDeep(this.brokerageDetails.brokerCompany.addressDetails));
      }
      else if(this.brokerageDetails.brokerContactDetails.isAddressSameAsBrokerCompany === 'differentAddress') {
        this.$set(this.brokerageDetails.brokerContactDetails, 'addressDetails', {});
      }
    }
  }

};
</script>
