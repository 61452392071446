<template>
<ValidationObserver :ref="`insuredEntity${reference}`">
  <div class="form-group">

    <div class="form-group row mb-1" v-if="showAbnSearch">
      <div class="col-6 row">
        <label class="col-3 col-form-label">Search ABN<span class="text-danger">*</span></label>
        <span class="col-5">
          <ValidationProvider :rules="{ regex: /^[\d\s]+$/ }" :name="`abnSearch${reference}`" v-slot="{ errors }">
            <input type="text" class="form-control" v-model="abnSearchString" :disabled="isReadOnly" maxlength="15"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </span>
        <button type="button" class="ml-3 col-3 btn btn-outline-primary form-control" @click.prevent="searchAbn"
                :disabled="searchingAbn || isReadOnly || !abnSearchString">Search</button>
        <b-spinner v-if="searchingAbn" variant="primary" class="ml-3 mt-1"></b-spinner>
      </div>
    </div>
    <div class="form-group" v-if="abnSearchError">
      <label class="text-danger">{{ abnSearchError }}</label>
    </div>

    <div class="form-group row mb-1 mt-5">
      <div class="col-12">
        <label class="col-form-label text-left">Full Insured Name<span class="text-danger">*</span></label>
        <ValidationProvider rules="required|max:255" :name="`legalEntity${reference}.fullInsuredName`" v-slot="{ errors }">
          <input type="text" class="form-control" v-model="legalEntity.fullInsuredName" disabled maxlength="255"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="form-group row mb-1">
      <div class="col-6">
        <label class="col-form-label text-left">ABN<span class="text-danger">*</span></label>
        <ValidationProvider rules="required" :name="`legalEntity${reference}.ABN`" v-slot="{ errors }">
          <input type="text" class="form-control" v-model="legalEntity.ABN" disabled/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">ACN</label>
        <input class="form-control" v-model="legalEntity.ACN" disabled/>
      </div>
    </div>
    <div class="form-group row mb-1">
      <div class="form-group col-6 mb-1">
        <label class="col-form-label text-right">Are you registered for GST?<span v-if="!isUwMode" class="text-danger">*</span></label>
        <b-form-group class="mb-1">
          <ValidationProvider :rules="{ required: !isUwMode }" :name="`legalEntity${reference}.isGSTregistered`" v-slot="{ errors }">
            <b-form-radio-group v-model="legalEntity.isGSTregistered" type="radio" :disabled="isReadOnly">
              <b-form-radio value="entityGSTregistered">Yes</b-form-radio>
              <b-form-radio value="entityGSTnotRegistered">No</b-form-radio>
            </b-form-radio-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-form-group>
      </div>

      <div class="form-group col-6 mb-1" v-if="legalEntity.isGSTregistered === 'entityGSTregistered'">
        <label class="col-form-label text-right">Input Tax Credit (%)<span v-if="!isUwMode" class="text-danger">*</span></label>
        <ValidationProvider :rules="{ required: !isUwMode, max_value: 100 }" :name="`legalEntity${reference}.inputTaxCredit`" v-slot="{ errors }">
          <input type="number" min="0" max="100" class="form-control" v-model="legalEntity.inputTaxCredit" :disabled="isReadOnly"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider> 
      </div>
    </div>
    <div class="form-group row mb-1">
      <div class="col-12">
        <label class="col-form-label text-left">Trading Name<span v-if="!isUwMode" class="text-danger">*</span></label>
        <ValidationProvider :rules="{ required: !isUwMode, max: 255 }" :name="`legalEntity${reference}.tradingName`" v-slot="{ errors }">
          <input type="text" class="form-control" v-model="legalEntity.tradingName" :disabled="isReadOnly"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider> 
      </div>
    </div>
    <div class="form-group row" mb-1>
      <div class="col-12">
        <label class="col-form-label text-left">Website</label>
        <input class="form-control" v-model="legalEntity.website" :disabled="isReadOnly"/>
        <b-form-text class="font-italic">
          Please ensure the website URL is in the following format: https://www.name.com
        </b-form-text>
      </div>
    </div>

    <div v-if="showAddress === true">
      Street Address
      <div class="form-group row mb-1">
        <div class="col-12">
          <address-details :address-entity="legalEntity.addressDetails" :is-read-only="isReadOnly" :mandatory="true"></address-details>
        </div>
      </div>
      <div class="mt-4">
        <label class="font-weight-bold">Is the Postal Address the same as the Street Address?<span class="text-danger">*</span></label>
      </div>
      <b-form-group v-slot="{ ariaDescribedby }">
        <ValidationProvider rules="required" :name="`legalEntity${reference}.isPostalAddressSame`" v-slot="{ errors }">
          <b-form-radio-group
              v-model="legalEntity.isPostalAddressSame"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="sm"
              :disabled="isReadOnly"
          >
            <b-form-radio value="samePostalAddress">Yes</b-form-radio>
            <b-form-radio value="differentPostalAddress">No</b-form-radio>
          </b-form-radio-group>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-form-group>

      <div v-if="legalEntity.isPostalAddressSame === 'differentPostalAddress'">
        Postal Address
        <address-details :address-entity="legalEntity.postalAddressDetails" :is-read-only="isReadOnly" :mandatory="true"></address-details>
      </div>
    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import AddressDetails from "@/view/pages/wizard/components/AddressDetails.vue";

export default {
  name: "InsuredEntity",

  components: {
    AddressDetails
  },

  props: {
    legalEntity: Object,
    reference: String,
    showAbnSearch: {
      type: Boolean,
      default: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    isReadOnly: Boolean,
    isUwMode: Boolean
  },

  data() {
    return {
      abnSearchString: null,
      abnSearchError: null,
      searchingAbn: false,

      postalAddressOptions: [
        { text: "Yes", value: "samePostalAddress" },
        { text: "No", value: "differentPostalAddress" },
      ],
    };
  },

  methods: {
    searchAbn: function() {
      if(!this.abnSearchString) {
        return;
      }

      this.abnSearchError = null;
      this.searchingAbn = true;

      this.$set(this.legalEntity, 'ABN', null);
      this.$set(this.legalEntity, 'ACN', null);
      this.$set(this.legalEntity, 'fullInsuredName', null);

      let searchString = this.abnSearchString.replace(/\s/g, '');
      let serverUrl = this.$apiService.getServerUrl();
      this.$apiService.get(`${serverUrl}/mpf/abn/search`,
          { params: {searchString: searchString} }
      )
        .then(({ data }) => {
          let abnInfo = data.result;

          if(!abnInfo || !abnInfo.abn || !abnInfo.entityName) {
            this.abnSearchError = 'No entity found for the given ABN';
          }
          else if(abnInfo.abnStatus !== 'Active') {
            this.abnSearchError = 'This ABN is not in active status - please confirm';
          }
          else {
            this.$set(this.legalEntity, 'ABN', abnInfo.abn);
            this.$set(this.legalEntity, 'ACN', abnInfo.acn || null);
            this.$set(this.legalEntity, 'fullInsuredName', abnInfo.entityName);
            this.abnSearchString = null;
          }

          this.searchingAbn = false;
        })
        .catch((error) => {
          console.log(error);
          this.searchingAbn = false;
          this.abnSearchError = 'Error while searching ABN - please try again';
        });
    }
  }

};
</script>
