var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"clientContact"},[_c('div',{staticClass:"form-group mb-5"},[_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v(" First Name"),(_vm.mandatory)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":_vm.mandatory ? 'required|max:255' : 'max:255',"name":"clientContactEntry.firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactDetails.firstName),expression:"contactDetails.firstName"}],staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly,"maxlength":"255"},domProps:{"value":(_vm.contactDetails.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactDetails, "firstName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v(" Last Name"),(_vm.mandatory)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":_vm.mandatory ? 'required|max:255' : 'max:255',"name":"clientContactEntry.lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactDetails.lastName),expression:"contactDetails.lastName"}],staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly,"maxlength":"255"},domProps:{"value":(_vm.contactDetails.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactDetails, "lastName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v(" Title/Company Position"),(_vm.mandatory)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":_vm.mandatory ? 'required|max:255' : 'max:255',"name":"clientContactEntry.title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactDetails.title),expression:"contactDetails.title"}],staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly,"maxlength":"255"},domProps:{"value":(_vm.contactDetails.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactDetails, "title", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("Email"),(_vm.mandatory)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":_vm.mandatory ? 'required|email|max:100' : 'email|max:100',"name":"clientContactEntry.email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactDetails.email),expression:"contactDetails.email"}],staticClass:"form-control",attrs:{"type":"email","disabled":_vm.isReadOnly,"maxlength":"100"},domProps:{"value":(_vm.contactDetails.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactDetails, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("Phone"),(_vm.mandatory)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":_vm.mandatory ? 'required|max:15' : 'max:15',"name":"clientContactEntry.phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactDetails.phone),expression:"contactDetails.phone"}],staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly},domProps:{"value":(_vm.contactDetails.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactDetails, "phone", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("Mobile"),(_vm.mandatory)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":_vm.mandatory ? 'required|max:15' : 'max:15',"name":"clientContactEntry.mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactDetails.mobile),expression:"contactDetails.mobile"}],staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly},domProps:{"value":(_vm.contactDetails.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactDetails, "mobile", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }