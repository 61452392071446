<template>
<ValidationObserver :ref="`filesUpload_${uploadKey}`">
  <div>
    <div class="form-group row mt-2 mb-2" v-if="!isReadOnly">
      <div class="col-6">
        <b-form-file class="form-control" v-model="selectedFiles" :disabled="uploadInProgress" :placeholder="placeholderText"
                     :multiple="allowMultiple"
        ></b-form-file>
      </div>
      <div class="col-2">
        <button class="btn btn-primary" @click.prevent="uploadFiles" :disabled="uploadInProgress">
          Upload
        </button>
        <b-spinner v-if="uploadInProgress" class="ml-3 align-middle" variant="primary" label="Uploading..."></b-spinner>
      </div>

    </div>
    <div v-if="!isOptional">
      <ValidationProvider rules="required" name="uploadedFiles" v-slot="{ errors }">
        <input type="hidden" disabled name="uploadedFiles" v-model="uploadedFiles"/>
        <span id="error" class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <label class="col-form-label text-left mt-2 text-danger" v-if="errorMessage">
      <strong>{{ errorMessage }}</strong>
    </label>

    <div v-if="Array.isArray(uploadedFiles)">
      <label class="col-form-label text-left font-weight-bold">
        Uploaded Files: <span v-if="uploadedFiles.length === 0" class="text-muted">None</span>
      </label>
      <div class="form-group mb-1">
        <table class="table table-sm col-8 table-bordered">
          <tr v-for="(fileInfo, index) in uploadedFiles" :key="index">
            <td width="70%"><a :href="fileInfo.viewLink" target="_blank">{{ fileInfo.name }}</a></td>
<!--            <td width="15%">{{ formatSize(fileInfo.size) }}</td>-->
            <td width="25%">
              <span v-if="fileInfo.createdTime">
                {{ fileInfo.createdTime | dateParse() | dateFormat("DD/MM/YYYY hh:mm A") }}
              </span>
            </td>
            <td width="5%" v-if="allowDeletion && !isReadOnly">
              <button class="btn btn-sm btn-outline-danger p-1"
                  @click.prevent="deleteFile" title="Delete"
              >
                <i class="fa fa-trash-alt p-0"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";

export default {
  name: "FilesUpload",

  props: {
    uploadKey: String,
    uploadedFiles: Array,
    uploadInProgress: Boolean,
    allowMultiple: Boolean,
    allowDeletion: Boolean,
    isOptional: Boolean,
    isReadOnly: Boolean
  },
 
  data() {
    return {
      selectedFiles: null,
      errorMessage: null
    };
  },
  
  methods: {
    uploadFiles: function () {
      if(!this.selectedFiles) {
        this.errorMessage = `Please select a file to upload`;
        return;
      }

      const filesToBeUploaded = this.allowMultiple ? this.selectedFiles : [ this.selectedFiles ];
      if(!Array.isArray(filesToBeUploaded) || filesToBeUploaded.length === 0) {
        this.errorMessage = `Please select a file to upload`;
        return;
      }

      this.errorMessage = null;
      this.$emit('uploadFiles', this.uploadKey, filesToBeUploaded);
    },

    deleteFile: function (file) {
      if(!this.allowDeletion) {
        this.errorMessage = `File deletion is not allowed`;
        return;
      }
      this.errorMessage = null;

      this.$emit('deleteFile', this.uploadKey, file.id);
    },

    formatSize: function (bytes) {
      if(_.isNil(bytes)) {
        return "";
      }

      if (bytes < 1024) return bytes + " Bytes";
      else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + " KB";
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + " MB";
      else return (bytes / 1073741824).toFixed(2) + " GB";
    },
  },

  computed: {
    placeholderText: function () {
      if(this.allowMultiple) {
        return 'Drag-and-drop files here, or click here to select files';
      }
      return 'Drag-and-drop a file here, or click here to select a file';
    }
  }
};
</script>
