<template>
<ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <div class="mb-10 font-weight-bold text-dark"  v-if="formData && referenceData">

      <!-- begin: Business Information -->
      <h3>BUSINESS INFORMATION</h3>
      <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-7 py-5">

        <!-- Rentsure/CRI Business Activities -->
        <div v-if="formData.brand !== 'Fuse'">
          <p class="font-italic">
            Your business may be a blend of different activities. Please provide the applicable percentage to each business activity below.
          </p>

          <table class="table table-sm table-bordered">
            <thead>
              <th width="75%" class="text-center">Business Activity</th>
              <th width="20%" class="text-center">Percentage (%)</th>
              <th width="5%" class="text-center"></th>
            </thead>
            <tr v-for="(businessActivity, index) in formData.businessActivityList" :key="index">
              <td>
                <ValidationProvider rules="required" :name="`businessActivity${index}.key`" v-slot="{ errors }">
                  <b-form-select class="custom-select custom-select-md" v-model="businessActivity.key" :disabled="isReadOnly">
                    <option v-for="ba in referenceData.businessActivities" :value="ba.K_BusinessActivityKey" :key="ba.K_BusinessActivityKey">
                      {{ ba.Name }}
                    </option>
                  </b-form-select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <p class="mb-0 pl-1 text-dark" v-if="showRideshareInfoMessage(businessActivity)">
                  Note - Minimum age restriction for Rideshare is 25.
                </p>
              </td>
              <td>
                <ValidationProvider rules="required|min_value:0.1|max_value:100" :name="`businessActivity${index}.percentage`" v-slot="{ errors }">
                  <input type="number" min="0.01" max="100" class="form-control" v-model="businessActivity.percentage" :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <button class="btn btn-sm btn-outline-danger" @click.prevent="deleteBusinessActivity(index)" title="Delete" v-if="!isReadOnly">
                  <i class="fa fa-trash-alt"></i>
                </button>
              </td>
            </tr>
            <tr>
              <button class="btn btn-outline-primary btn-sm ml-2 mt-2" @click.prevent="addBusinessActivity()" v-if="!isReadOnly">
                Add Business Activity
              </button>
            </tr>
            <tr>
              <td class="text-right"><label class="col-form-label"><strong>Total</strong></label></td>
              <td style="vertical-align: center">
                <label class="col-form-label ml-2"><strong>{{ totalPercentageOfBusinessActivities }} %</strong></label>
                <ValidationProvider rules="required|totalPercentage" name="totalPercentageOfBusinessActivities" v-slot="{ errors }">
                  <input type="number" v-model="totalPercentageOfBusinessActivities" hidden/>
                  <span class="text-danger"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
          </table>

          <div class="form-group row mb-0 mt-5">
            <div class="form-group col-12 mb-1">
              <label class="col-form-label text-right">
                <strong>Are vehicles rented to persons under 21 years of age?<span class="text-danger">*</span></strong>
              </label>
              <b-form-group class="mb-1">
                <ValidationProvider rules="required" name="businessInfo.vehicleUserUnder21Years" v-slot="{ errors }">
                  <b-form-radio-group id="radio-group-user-under-21-years" name="businessInfo.vehicleUserUnder21Years"
                                      v-model="formData.businessInfo.vehicleUserUnder21Years" type="radio" :disabled="isReadOnly"
                  >
                    <b-form-radio type="radio" value="Yes">Yes</b-form-radio>
                    <b-form-radio type="radio" value="No">No</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>
        </div>
        <!-- End of Rentsure/CRI Business Activities -->

        <!-- Fuse Business Activities -->
        <div v-if="formData.brand === 'Fuse'" class="mb-0">

          <label class="col-form-label font-size-h6"><strong>Enter Your Business Activities</strong></label>
          <b-card body-class="p-3">
            <div v-if="!isReadOnly" class="form-group mb-0">
              <b-form-radio name="fuseBusinessActivitiesEntryType" value="businessActivitiesBic" size="lg"
                            v-model="formData.fuseBusinessActivitiesEntryType" @input="baEntryTypeChanged" :disabled="isReadOnly">
                <label class="col-form-label text-dark pt-1"><b>Search Business Industry Codes</b></label>
                <i id="bicIcon" class="fas fa-question-circle text-primary ml-2" v-b-popover.hover.right="bicTooltipData"></i>
              </b-form-radio>
              <div v-if="baEntryTypeBic" class="form-group mb-3">
                <div class="row">
                  <div class="col-8">
                    <v-autocomplete
                        class="mt-0 pt-0 mb-0" color="white"
                        label="Start typing business description (minimum 3 characters)"
                        return-object multiple small-chips clearable deletable-chips density="compact"
                        :items="referenceData.businessCodes" v-model="selectedBics" :item-text="getBusinessCodeText"
                        :search-input.sync="searchBic"
                        :disabled="isReadOnly"
                    ></v-autocomplete>
                  </div>
                  <button type="button" class="col-1 btn btn-sm btn-outline-primary p-2 form-control"
                          @click.prevent="addBics" :disabled="isReadOnly">Add</button>
                </div>
              </div>

              <b-form-radio name="fuseBusinessActivitiesEntryType" value="businessActivitiesManual" class="mb-3 mt-3"  size="lg"
                            v-model="formData.fuseBusinessActivitiesEntryType" @input="baEntryTypeChanged" :disabled="isReadOnly">
                <label class="col-form-label text-dark pt-1"><b>Enter Business Activities Manually</b></label>
              </b-form-radio>
              <div v-if="baEntryTypeManual" class="form-group mb-3">
                <div class="row">
                  <div class="col-8">
                    <input type="text" maxlength="255" class="form-control" v-model="manualBA"
                             :disabled="isReadOnly"/>
                  </div>
                  <button type="button" class="col-1 btn btn-sm btn-outline-primary p-2 form-control"
                          @click.prevent="addManualBA" :disabled="isReadOnly">Add</button>
                </div>
              </div>
              <ValidationProvider rules="required" name="fuseBusinessActivitiesEntryTypeCheck" v-slot="{ errors }">
                <input type="hidden" class="form-control" v-model="formData.fuseBusinessActivitiesEntryType"
                       :disabled="isReadOnly"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="mt-10">
              <strong>Selected Business Activities: </strong><span v-if="!fuseMainBusinessActivitiesSelected">None</span>
              <table class="table table-sm table-bordered col-8" v-if="fuseMainBusinessActivitiesSelected">
                <tr v-for="(ba, index) in formData.mainBusinessActivitiesFuse" :key="index">
                  <td width="95%" style="vertical-align: center">{{ ba.title }}</td>
                  <td width="5%" class="text-center">
                    <button class="btn btn-sm btn-outline-danger p-1" @click.prevent="deleteBA(index)" title="Delete" v-if="!isReadOnly">
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </table>
              <ValidationProvider :rules="{ 'required': { allowFalse: false }}" name="fuseMainBicsSelected" v-slot="{ errors }">
                <input v-model="fuseMainBusinessActivitiesSelected" hidden/>
                <span class="text-danger"><br>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </b-card>

          <div class="mt-8">
            <label class="col-form-label font-size-h6"><strong>Specify Your Vehicle Use</strong></label>
            <table class="table table-sm table-bordered col-12">
              <thead>
                <th width="80%" class="text-center">Vehicle Use</th>
                <th width="20%" class="text-center">Fleet Mix (%)</th>
              </thead>
              <tr>
                <td>
                  <label class="col-form-label">Low - Private use, typically makes 2 or less trips a day, less than 10,000 annual km travelled.</label>
                </td>
                <td class="text-center">
                  <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseLow" v-slot="{ errors }">
                    <input type="number" min="0" max="100" class="form-control" v-model="formData.vehicleUse.lowPercent" :disabled="isReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="col-form-label">Medium - On the road but less than half the day, 3-5 trips a day, 10,000 to 20,000 annual km travelled.</label>
                </td>
                <td class="text-center">
                  <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseMedium" v-slot="{ errors }">
                    <input type="number" min="0" max="100" class="form-control" v-model="formData.vehicleUse.mediumPercent" :disabled="isReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="col-form-label">High - On the road for most of the day, 5-10 trips a day, 20,000 to 30,000 annual km travelled.</label>
                </td>
                <td class="text-center">
                  <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseHigh" v-slot="{ errors }">
                    <input type="number" min="0" max="100" class="form-control" v-model="formData.vehicleUse.highPercent" :disabled="isReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="col-form-label">Extreme - On the road for the whole day, 10+ trips a day, more than 30,000 annual km travelled.</label>
                </td>
                <td class="text-center">
                  <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseExtreme" v-slot="{ errors }">
                    <input type="number" min="0" max="100" class="form-control" v-model="formData.vehicleUse.extremePercent" :disabled="isReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="text-right" style="vertical-align: middle">Total</td>
                <td class="text-center">
                  <label class="col-form-label ml-2"><strong>{{ totalVehicleUsePercent }} %</strong></label>
                  <ValidationProvider rules="required|totalPercentage" name="totalVehicleUsePercent" v-slot="{ errors }">
                    <input type="number" v-model="totalVehicleUsePercent" hidden/>
                    <span class="text-danger"><br>{{ errors[0] }}</span>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- End of Fuse Business Activities -->

        <!-- NSW Stamp Duty Exemption -->
        <div class="form-group row">
          <div class="form-group col-12 mb-0">
            <label class="col-form-label text-right">
              <strong>Is your business exempt from NSW Stamp Duty?<span v-if="!isUwMode" class="text-danger">*</span>
                <i id="nswSdIcon" class="fas fa-question-circle text-primary ml-2"
                   v-b-popover.hover.right="nswSdTooltipData"
                ></i>
              </strong>
            </label>
            <b-form-group class="mb-1">
              <ValidationProvider :rules="{ required: !isUwMode }" name="businessInfo.businessExemptFromStampDuty" v-slot="{ errors }">
                <b-form-radio-group
                    id="radio-group-business-exempt-from-stamp-duty" name="businessInfo.businessExemptFromStampDuty"
                    v-model="formData.businessInfo.businessExemptFromStampDuty" type="radio" :disabled="isReadOnly"
                >
                  <b-form-radio type="radio" value="Yes">Yes</b-form-radio>
                  <b-form-radio type="radio" value="No">No</b-form-radio>
                </b-form-radio-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <div v-if="formData.businessInfo.businessExemptFromStampDuty === 'Yes'">
              To obtain Stamp Duty exemption, the declaration form must be completed and returned prior to the inception
              of cover. Declaration form will be sent to you separately by email.
            </div>
          </div>
        </div>

        <div class="form-group row mb-1">
          <div class="col-6 mb-1">
            <label class="col-form-label text-left">
              <strong>
                How many years has this business been in operation?<span class="text-danger">*</span>
              </strong>
            </label>
            <ValidationProvider rules="required" name="ownershipAndCover.yearsOperatedRentalVehicleBusiness" v-slot="{ errors }">
              <input class="form-control" v-model="formData.ownershipAndCover.yearsOperatedRentalVehicleBusiness"
                     type="number" min="0" @input="updateFleetHistoryYears" :disabled="isReadOnly"/>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-6 mb-1">
            <label class="col-form-label text-left">
              <strong>What is your estimated average fleet number for the next 12 months?<span class="text-danger">*</span>
              </strong>
            </label>
            <ValidationProvider rules="required" name="ownershipAndCover.estimatedAvgFleetNumber" v-slot="{ errors }">
              <input class="form-control" v-model="formData.ownershipAndCover.estimatedAvgFleetNumber" type="number" min="1" :disabled="isReadOnly"/>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="isRenewal" class="mt-5">
          <div class="form-group row mb-1">
            <div class="col-12 mb-1">
              <label class="col-form-label">
                <strong>
                  Is there any loss, damage, or pending claim notified to you or your company, which may result in a claim on Us that
                  You have not notified to Us, or are required to notify Us under the Corporations Act which will material affect our
                  decision to offer renewal, and on what terms?  If Yes, please now provide those details.<span class="text-danger">*</span>
                </strong>
              </label>
              <b-form-group class="mb-1">
                <ValidationProvider rules="required" name="ownershipAndCover.lossDamagePendingClaim" v-slot="{ errors }">
                  <b-form-radio-group v-model="formData.ownershipAndCover.lossDamagePendingClaim" type="radio" :disabled="isReadOnly">
                    <b-form-radio type="radio" value="Yes">Yes</b-form-radio>
                    <b-form-radio type="radio" value="No">No</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>
          <div v-if="formData.ownershipAndCover.lossDamagePendingClaim === 'Yes'">
            <div class="form-group row mb-1">
              <div class="col-12 mb-1">
                <label class="col-form-label">
                  <strong>
                    Details:<span class="text-danger">*</span>
                  </strong>
                </label>
                <ValidationProvider rules="required|max:2000" name="ownershipAndCover.lossDamagePendingClaimDetails" v-slot="{ errors }">
                  <b-form-textarea
                      v-model="formData.ownershipAndCover.lossDamagePendingClaimDetails" rows="4" :disabled="isReadOnly"
                  ></b-form-textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div v-if="formData.brand !== 'Fuse'" class="form-group row mb-1">
            <div class="col-12 mb-1">
              <label class="col-form-label">
                <strong>
                  Has there been any change in your Rental Agreement?<span class="text-danger">*</span>
                </strong>
              </label>
              <b-form-group class="mb-1">
                <ValidationProvider rules="required" name="ownershipAndCover.changeInRentalAgreement" v-slot="{ errors }">
                  <b-form-radio-group
                      v-model="formData.ownershipAndCover.changeInRentalAgreement" type="radio" :disabled="isReadOnly"
                  >
                    <b-form-radio type="radio" value="Yes">Yes</b-form-radio>
                    <b-form-radio type="radio" value="No">No</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>
          <div v-if="formData.ownershipAndCover.changeInRentalAgreement === 'Yes'">
            <div class="form-group row mb-1">
              <div class="col-12 mb-1">
                <label class="col-form-label">
                  <strong>
                    Please attach a copy of your new rental agreement<span class="text-danger">*</span>
                  </strong>
                </label>
                <files-upload
                    :upload-key="'rentalVehicleAgreement'" :uploaded-files="formData.formUploads.rentalVehicleAgreement"
                    :upload-in-progress="uploadProgress.rentalVehicleAgreement"
                    :is-read-only="isReadOnly" v-on="$listeners">
                </files-upload>
              </div>
            </div>
          </div>
        </div>

      </b-card>
      <!-- end: Business Information -->

    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
.nswsd-tooltip {
  max-width: 400px !important;
}
</style>

<script>
// import FileUpload from "@/view/pages/wizard/motor-proposal-form/components/FileUpload.vue";
import _ from "lodash";
import FilesUpload from "@/view/pages/wizard/motor-proposal-form/components/FilesUpload.vue";

export default {
  name: "WizardPage3",

  components: {
    FilesUpload,
    // FileUpload
  },

  props: {
    formData: Object,
    referenceData: Object,
    uploadProgress: Object,
    isReadOnly: Boolean,
    isRenewal: Boolean,
    isUwMode: Boolean
  },

  data() {
    return {
      searchBic: null,
      selectedBics: [],
      manualBA: null
    };
  },

  computed: {

    baEntryTypeManual: function () {
      return this.formData?.fuseBusinessActivitiesEntryType === 'businessActivitiesManual';
    },

    baEntryTypeBic: function () {
      return this.formData?.fuseBusinessActivitiesEntryType === 'businessActivitiesBic';
    },

    totalPercentageOfBusinessActivities: function() {
      let total = 0;
      if(this.formData && Array.isArray(this.formData.businessActivityList)) {
        for (let businessActivity of this.formData.businessActivityList) {
          total += parseFloat(businessActivity.percentage) || 0;
        }
      }
      return total;
    },

    totalVehicleUsePercent: function() {
      let total = 0;
      if(this.formData) {
        for (const key in this.formData.vehicleUse) {
          total += parseFloat(this.formData.vehicleUse[key]) || 0;
        }
      }
      return total;
    },

    fuseMainBusinessActivitiesSelected: function() {
      if(this.formData && Array.isArray(this.formData.mainBusinessActivitiesFuse)) {
        return this.formData.mainBusinessActivitiesFuse.length > 0;
      }
      return false;
    },

    nswSdTooltipData: function() {
      return {
        title: 'NSW Insurance Stamp Duty Exemption Declaration',
        html: true,
        customClass: 'nswsd-tooltip',
        content: `<ol>
                    <li>From 1 January 2018 eligible small businesses (generally with less than $2million turnover) will not be required to pay duty on certain types of insurance, including:</li>
                    <li>Commercial vehicle insurance – for a motor vehicle used primarily for business purposes</li>
                    <li>Occupational indemnity insurance (or professional indemnity) – covering liability arising out of the provision of professional or other services (other than medical indemnity insurance)</li>
                    <li>Product and public liability insurance – insurance covering liability for personal injury or property damage occurring in connection with, or arising out of the products or services of, a business.</li>
                  </ol>
                  <strong>What is a small business?</strong><br/>
                  <ol>
                    <li>You are a small business for the current year if:</li>
                    <li>you carry on a business in the current year; and</li>
                    <li>one or both of the following applies:</li>
                    <ul>
                      <li>you carried on a business in the previous year and your aggregated turnover for that previous year was less than $2million;</li>
                      <li>your aggregated turnover for the current year is likely to be less than $2million.</li>
                    </ul>
                  </ol>`
      }
    },

    bicTooltipData: function() {
      return {
        title: 'Business Industry Codes',
        html: true,
        customClass: 'bic-tooltip',
        content: `<ul>
                    <li>A business industry code (BIC) is a five-digit code you include on relevant tax returns and schedules that describes your main business activity.</li>
                    <li>BICs are derived from the Australian and New Zealand Standard Industrial Classification (ANZSIC) codes and have been simplified for tax return reporting purposes.</li>
                  </ul>
                  <ul>For more information, please refer to -
                  <a target="_blank" href="https://www.ato.gov.au/calculators-and-tools/business-industry-code-tool-anzsic-coder">Business Industry Code Tool</a>
                  </ul>
                 `
      }
    }
  },

  watch: {
    searchBic(searchString) {
      if (!searchString) {
        searchString = "";
      }
      if(searchString.length > 2) {
        this.$emit('searchBusinessCodes', searchString);
      }
    },
  },

  methods: {

    showRideshareInfoMessage: function(businessActivity) {
      return businessActivity.key === 'rideShare' && parseFloat(businessActivity.percentage)>25;
    },

    addBusinessActivity: function () {
      this.formData.businessActivityList.push({});
    },

    deleteBusinessActivity: function (index) {
      this.formData.businessActivityList.splice(index, 1);
    },

    getBusinessCodeText: function (item) {
      return item?.title;
    },

    baEntryTypeChanged: function () {
      if(this.baEntryTypeBic) {
        this.manualBA = null;
      }
      else if(this.baEntryTypeManual) {
        this.$set(this, 'selectedBics', []);
      }
    },

    addBics: function () {
      if(Array.isArray(this.selectedBics) && this.selectedBics.length > 0) {
        const mergedArray = (this.formData.mainBusinessActivitiesFuse || []).concat(this.selectedBics);
        this.$set(this.formData, 'mainBusinessActivitiesFuse', mergedArray);

        this.selectedBics.splice(0, this.selectedBics.length);
      }
    },

    addManualBA: function () {
      if(this.manualBA) {
        let mainBusinessActivitiesFuse = (this.formData.mainBusinessActivitiesFuse || []);
        mainBusinessActivitiesFuse.push({ title : this.manualBA });
        this.$set(this.formData, 'mainBusinessActivitiesFuse', mainBusinessActivitiesFuse);

        this.manualBA = null;
      }
    },

    deleteBA: function (index) {
      this.formData.mainBusinessActivitiesFuse.splice(index, 1);
    },

    updateFleetHistoryYears: function() {
      let applicableYears = parseInt(_.get(this.formData, 'ownershipAndCover.yearsOperatedRentalVehicleBusiness'));
      if(!applicableYears) {
        applicableYears = 1;
      }
      else if(applicableYears >= 5) {
        applicableYears = 5;
      }

      const historyYears = ["Current year", "1 year ago", "2 years ago", "3 years ago", "4 years ago"];

      const newClaimsHistory = [];
      const newFleetHistory = [];
      for (const [i, historyYear] of historyYears.entries()) {
        if(i === applicableYears) {
          break;
        }
        let existingYear = _.find(this.formData.insuranceClaimsAndLossHistory.claimsHistory, { 'year': historyYear });
        if(existingYear) {
          newClaimsHistory.push(existingYear);
        }
        else {
          newClaimsHistory.push({ year: historyYear });
        }

        existingYear = _.find(this.formData.fleetHistory, { 'year': historyYear });
        if(existingYear) {
          newFleetHistory.push(existingYear);
        }
        else {
          newFleetHistory.push({ year: historyYear });
        }
      }

      this.formData.insuranceClaimsAndLossHistory.claimsHistory = newClaimsHistory;
      this.formData.fleetHistory = newFleetHistory;
    }
  },
};
</script>
