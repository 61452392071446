<template>
<ValidationObserver ref="addressDetails">
  <div class="form-group mb-0">
    <div class="form-group row mb-0">
      <div class="col-6">
        <label class="col-form-label text-left font-weight-bold font-size-h6">Search for an Address</label>
          <v-autocomplete
            class="mt-0 pt-0 mb-0"
            :items="addressCompletionsArray"
            v-model="addressEntity.address"
            :search-input.sync="search"
            @change="getAddressDetails"
            color="white"
            :item-text="getItemText"
            label="Start typing the address (minimum 3 characters)"
            return-object
            :disabled="isReadOnly"
          ></v-autocomplete>
         
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">Street Address<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="addressEntity.streetAddress" v-slot="{ errors }">
          <input type="text" class="form-control" v-model="addressEntity.streetAddress" :disabled="isReadOnly" maxlength="255"
                 @input="notifyAddressChanged" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="form-group row mb-1">
      <div class="col-6">
        <label class="col-form-label text-left">City/Suburb<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="addressEntity.suburb" v-slot="{ errors }">
          <input type="text" class="form-control" v-model="addressEntity.suburb" :disabled="isReadOnly" maxlength="255"
                 @input="notifyAddressChanged" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">Postcode<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required|max:10' : 'max:10'" name="addressEntity.postcode" v-slot="{ errors }">
          <input type="text" class="form-control" v-model="addressEntity.postcode" :disabled="isReadOnly" maxlength="10"
                 @input="notifyAddressChanged" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="form-group row mb-0">
      <div class="col-6">
        <label class="col-form-label text-left">State<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required' : ''" name="addressEntity.state" v-slot="{ errors }">
          <b-form-select class="custom-select custom-select-md" v-model="addressEntity.state" name="addressEntity.state"
              :disabled="isReadOnly" @input="notifyAddressChanged"
          >
            <option value="">Select State</option>
            <option value="NSW">NSW</option>
            <option value="VIC">VIC</option>
            <option value="QLD">QLD</option>
            <option value="WA">WA</option>
            <option value="SA">SA</option>
            <option value="ACT">ACT</option>
            <option value="TAS">TAS</option>
            <option value="NT">NT</option>
            <option value="NT">Overseas</option>
          </b-form-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">Country<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="addressEntity.country" v-slot="{ errors }">
          <input type="text" class="form-control" v-model="addressEntity.country" :disabled="isReadOnly" maxlength="255"
                 @input="notifyAddressChanged" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
export default {
  name: "AddressDetails",

  props: {
    addressEntity: Object,
    isReadOnly: Boolean,
    mandatory: Boolean
  },

  data() {
    return {
      search: null,
      addressCompletionsArray: []
    };
  },

  watch: {
    search(searchString) {
      if (!searchString) {
        searchString = "";
      }
      if(searchString.length > 2) {
        this.searchAddress(searchString);
      }
    },
  },

  methods: {
    getItemText: function (item) {
      return `${item.full_address}`;
    },

    searchAddress: function (searchString) {
      if(this.isReadOnly) {
        return;
      }

      let serverUrl = this.$apiService.getServerUrl();
      this.$apiService.get(`${serverUrl}/mpf/address/search`,
          { params: {searchString: searchString}}
      )
        .then(({ data }) => {
          this.addressCompletionsArray = data.result;
        })
        .catch((error) => {
          console.log("UI Error in searchAddress:", error);
        });
    },

    getAddressDetails: function () {
      if(this.isReadOnly) {
        return;
      }

      let addressId = null;
      if(this.addressEntity && this.addressEntity.address) {
        addressId = this.addressEntity.address.id;
      }

      if (addressId) {
        let serverUrl = this.$apiService.getServerUrl();
        this.$apiService.get(`${serverUrl}/mpf/address/${addressId}`)
          .then(({ data }) => {
            let userSelectedAddress = data.result;

            this.$set(this.addressEntity, 'postcode', userSelectedAddress.postcode);
            this.$set(this.addressEntity, 'state', userSelectedAddress.state_territory);
            this.$set(this.addressEntity, 'streetAddress', userSelectedAddress.address_line_1 || "");
            this.$set(this.addressEntity, 'suburb', userSelectedAddress.locality_name);
            this.$set(this.addressEntity, 'country', "Australia");
            if (userSelectedAddress.address_line_2) {
              this.$set(this.addressEntity, 'streetAddress', `${this.addressEntity.streetAddress}, ${userSelectedAddress.address_line_2}`);
            }

            this.notifyAddressChanged();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    notifyAddressChanged: function() {
      this.$emit('addressUpdated', this.addressEntity);
    }
  },
};
</script>
