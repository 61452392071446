<template>
<ValidationObserver ref="wizardPage6" id="wizardPage6" name="wizardPage6">
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <div class="mb-10 font-weight-bold text-dark" v-if="formData && referenceData">

      <!-- begin: Fleet History-->
      <div v-if="!isRenewal && Array.isArray(formData.fleetHistory) && formData.fleetHistory.length>0">
        <h3>FLEET HISTORY</h3>
        <b-card border-variant="primary" body-class="px-7 py-5">
          <strong>
            Please enter the average number of vehicles for each year below
            (covered by Comprehensive and/or TPPD insurance) as well as the
            average policy excess for the year.<br /><br />
            <p class="text-center"> <u>AVERAGE NUMBER OF VEHICLES</u></p>
          </strong>

          <table class="table table-sm table-bordered mt-3">
            <thead>
            <th width="25%" class="text-center"></th>
            <th width="15%" class="text-center">Comprehensive</th>
            <th width="15%" class="text-center">TPPD</th>
            <th width="15%" class="text-center">Total Fleet</th>
            <th width="15%" class="text-center">Comprehensive Excess</th>
            <th width="15%" class="text-center">TPPD Excess</th>
            </thead>
            <tr v-for="(applicableYear, index) in formData.fleetHistory" v-bind:key="index">
              <td class="text-center pt-3">
                {{ applicableYear.year }}
              </td>
              <td>
                <ValidationProvider rules="required" name="fleetHistory.applicableYear.comprehensive" v-slot="{ errors }">
                  <input
                      class="form-control" v-model.number="applicableYear.comprehensive" type="number" min="0" :disabled="isReadOnly"
                      @input="calculateTotalFleet(index)"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="required" name="fleetHistory.applicableYear.tppd" v-slot="{ errors }">
                  <input
                      class="form-control" v-model.number="applicableYear.tppd" type="number" min="0" :disabled="isReadOnly"
                      @input="calculateTotalFleet(index)"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <input class="form-control" disabled v-model="applicableYear.totalFleet"/>
              </td>
              <td>
                <ValidationProvider rules="required" name="fleetHistory.applicableYear.comprehensiveExcess" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="applicableYear.comprehensiveExcess" :disabled="isReadOnly" />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="required" name="fleetHistory.applicableYear.tppdExcess" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="applicableYear.tppdExcess" :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
          </table>
        </b-card>
      </div>
      <!-- end: Fleet History-->

      <!-- begin: Fleet Declaration -->
      <div class="mt-5">
        <h3>FLEET DECLARATION</h3>
        <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">

          <strong>Choose one of the options below:</strong>
          <ValidationProvider rules="required" name="fleetDeclarationType" v-slot="{ errors }">
            <input type="hidden" v-model="formData.fleetDeclarationType"/>
            <span class="text-danger"><br>{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="form-group">
            <b-form-radio-group name="fleetDeclarationType" v-model="formData.fleetDeclarationType" :disabled="isReadOnly">

              <!-- New Fleet Upload using CSVBox -->
              <b-form-radio value="automatedFleetUpload" size="lg" class="mt-5">
                <label class="col-form-label font-weight-bold font-size-h6 text-dark pt-0">
                  <strong>RECOMMENDED</strong> - Use Automated Fleet Upload
                </label>
              </b-form-radio>

              <div v-if="formData.fleetDeclarationType === 'automatedFleetUpload'">
                <div v-if="!branchCodes || branchCodes.length === 0" class="form-group text-danger">
                  Please complete the business location information before uploading fleet details.
                </div>

                <div v-if="!isReadOnly && Array.isArray(branchCodes) && branchCodes.length > 0">
                  <div class="mb-2">Upload a list of vehicles that you propose to insure - noting the Registration Number, Registration State and the Business Location.</div>
                  <fleet-upload :csvbox-key="referenceData.cblk" :branch-codes="branchCodes" :unique-id="formId" v-on="$listeners"/>
                </div>

                <div v-if="formData.fleetUploadUniqueId" class="mt-5">
                  <strong class="text-success">Fleet Upload has been completed.</strong>
                  <br/>
                  <button class="btn btn-sm btn-secondary" @click.prevent="getFormUploadedFleet" :disabled="formData.gettingFormUploadedFleet">
                    View Uploaded Fleet
                  </button>
                  <b-spinner class="ml-3" style="vertical-align: middle" v-if="formData.gettingFormUploadedFleet"></b-spinner>
                </div>

                <ValidationProvider rules="required-action" name="fleetUploadUniqueId" v-slot="{ errors }">
                  <input type="hidden" v-model="formData.fleetUploadUniqueId"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- Manual Fleet Upload -->
              <div class="mt-10">
                <b-form-radio value="manualFleetUpload" size="lg">
                  <label class="col-form-label font-weight-bold font-size-h6 text-dark pt-0">
                    Enter Fleet Details Manually
                  </label>
                </b-form-radio>

                <div v-if="formData.fleetDeclarationType === 'manualFleetUpload'" class="mt-5">
                  <b-card body-class="px-3 py-3">
                    Please complete the table below. The details below do not address a risk which is Comprehensive and TPPD
                    and/or has multiple postcode locations. We will need to discuss these risk types with you directly
                    and collect the individual risk information separately.

                    <table class="table table-sm table-bordered mt-5">
                      <thead>
                        <th width="40%" class="align-middle text-center">Vehicle Type<span class="text-danger">*</span></th>
                        <th width="15%" class="align-middle text-center">Number of Vehicles<span class="text-danger">*</span></th>
                        <th width="20%" class="align-middle text-center">Total Market Value<span class="text-danger">*</span></th>
                        <th width="25%" class="align-middle text-center">Cover Type<span class="text-danger">*</span></th>
                        <th width="5%" class="align-middle text-center"></th>
                      </thead>
                      <tr v-for="(fleetType, index) in formData.fleetTypes" :key="index">
                        <td>
                          <ValidationProvider rules="required" name="fleetType.vehicleType" v-slot="{ errors }">
                            <b-form-select class="custom-select custom-select-sm" name="fleetType.vehicleType"
                                v-model="fleetType.vehicleType" :disabled="isReadOnly"
                            >
                              <option v-for="(vType, vIndex) in referenceData.vehicleTypes" :key="vIndex" :value="vType">
                                {{ vType.Name }}
                              </option>
                            </b-form-select>
                            <span class="text-danger">{{ errors[0] }}</span>
                            <span v-if="fleetType.vehicleType && fleetType.vehicleType.K_HelpText">
                              <p class="mb-0 pl-1 text-muted">{{ fleetType.vehicleType.K_HelpText }}</p>
                            </span>
                          </ValidationProvider>
                        </td>
                        <td>
                          <ValidationProvider rules="required" name="fleetType.noOfVehicles" v-slot="{ errors }">
                            <input type="number" class="form-control" v-model="fleetType.noOfVehicles" min="1" :disabled="isReadOnly"/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </td>
                        <td>
                          <ValidationProvider rules="required" name="fleetType.totalMarketValue" v-slot="{ errors }">
                            <currency-input class="form-control" v-model="fleetType.totalMarketValue" :disabled="isReadOnly"/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </td>
                        <td>
                          <ValidationProvider rules="required" name="fleetType.coverType" v-slot="{ errors }">
                            <b-form-select class="custom-select custom-select-sm"
                                 v-model="fleetType.coverType" :disabled="isReadOnly"
                            >
                              <option value="Section 1 & 2">Comprehensive (Section 1 & 2)</option>
                              <option value="Section 2 Only">TPPD (Section 2 Only)</option>
                            </b-form-select>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </td>
                        <td>
                          <button v-if="!isReadOnly" class="btn btn-sm btn-outline-danger" title="Delete"
                              @click.prevent="deleteVehicleTypeEntry(index)"
                          >
                            <i class="fa fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                      <button v-if="!isReadOnly" class="btn btn-primary m-2" @click.prevent="addVehicleTypeEntry">
                        Add Row
                      </button>
                      <ValidationProvider rules="min_count:1" name="numberOfVehicleTypes" v-slot="{ errors }">
                        <input type="number" v-model="formData.fleetTypes.length" hidden/>
                        <span class="text-danger"><br>{{ errors[0] }}</span>
                      </ValidationProvider>
                    </table>

                    <div class="mt-5">Please Attach Vehicle Schedule<span class="text-danger">*</span></div>
                    <files-upload
                        :upload-key="'fleetSchedule'" :uploaded-files="formData.formUploads.fleetSchedule"
                        :upload-in-progress="uploadProgress.fleetSchedule" :is-read-only="isReadOnly" v-on="$listeners">
                    </files-upload>
                  </b-card>
                </div>
              </div>

              <!-- Renewal Form - Use previous period fleet -->
<!--              <div v-if="isRenewal" class="mt-10">-->
<!--                <b-form-radio value="usePreviousFleet" size="lg">-->
<!--                  <label class="col-form-label font-weight-bold font-size-h6 text-dark pt-0">-->
<!--                    Use Fleet Declaration From Previous Policy Period-->
<!--                  </label>-->
<!--                </b-form-radio>-->
<!--                <br/>-->
<!--                <button class="btn btn-sm btn-secondary" @click.prevent="getPreviousPeriodFleet" :disabled="isReadOnly || formData.gettingPreviousPeriodFleet">View Previous Period Fleet</button>-->
<!--                <b-spinner class="ml-3" style="vertical-align: middle" v-if="formData.gettingPreviousPeriodFleet"></b-spinner>-->
<!--              </div>-->

            </b-form-radio-group>
          </div>
        </b-card>
      </div>
      <!-- end: Fleet Declaration -->

      <!-- begin: GPS/Telematics -->
      <div v-if="!isRenewal" class="mt-5">
        <h3>GPS/TELEMATICS</h3>
        <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
          <div class="form-group row mb-1">
            <div class="col-12 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Does any of your fleet have a GPS/telematic device fitted?
                </strong>
              </label>
              <b-form-group class="mb-1">
                <ValidationProvider rules="" name="modificationsAndAccessories.telematicFitted" v-slot="{ errors }">
                  <b-form-radio-group
                      id="modificationsAndAccessories-telematicFitted" name="modificationsAndAccessories.telematicFitted"
                      type="radio" v-model="formData.modificationsAndAccessories.telematicFitted" :disabled="isReadOnly"
                  >
                    <b-form-radio type="radio" value="Yes">Yes</b-form-radio>
                    <b-form-radio type="radio" value="No">No</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>

          <div v-if="formData.modificationsAndAccessories.telematicFitted === 'Yes'">
            <div class="form-group row mb-1">
              <div class="col-6 mb-1">
                <label class="col-form-label text-right">
                  <strong>
                    Percentage of the fleet with a telematic device fitted<span v-if="!isUwMode" class="text-danger">*</span>
                  </strong>
                </label>
                <ValidationProvider :rules="{ required: !isUwMode }" v-slot="{ errors }">
                  <input class="form-control" type="number" min="0.01" max="100" :disabled="isReadOnly"
                         v-model.number="formData.modificationsAndAccessories.telematicPercent"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6 mb-1">
                <label class="col-form-label text-right">
                  <strong>
                    Name of the telematic provider<span v-if="!isUwMode" class="text-danger">*</span>
                  </strong>
                </label>
                <ValidationProvider :rules="{ required: !isUwMode }" v-slot="{ errors }">
                  <input class="form-control" type="text" :disabled="isReadOnly"
                         v-model="formData.modificationsAndAccessories.telematicProvider"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <!--          <div class="form-group row mb-1">-->
          <!--            <div class="col-12 mb-1">-->
          <!--              <label class="col-form-label text-right">-->
          <!--                <strong>-->
          <!--                  Have any of the vehicles been modified in any way, other than Factory Fitted Options or Accessories?<span v-if="!isUwMode" class="text-danger">*</span>-->
          <!--                </strong>-->
          <!--              </label>-->
          <!--              <b-form-group class="mb-1">-->
          <!--                <ValidationProvider :rules="{ required: !isUwMode }" name="modificationsAndAccessories.haveVehiclesBeenModified" v-slot="{ errors }">-->
          <!--                  <b-form-radio-group-->
          <!--                    id="modificationsAndAccessories-haveVehiclesBeenModified" name="modificationsAndAccessories.haveVehiclesBeenModified"-->
          <!--                    type="radio" v-model="formData.modificationsAndAccessories.haveVehiclesBeenModified" :disabled="isReadOnly"-->
          <!--                  >-->
          <!--                    <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
          <!--                    <b-form-radio type="radio" value="No">No</b-form-radio>-->
          <!--                  </b-form-radio-group>-->
          <!--                  <span class="text-danger">{{ errors[0] }}</span>-->
          <!--                </ValidationProvider>-->
          <!--              </b-form-group>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div v-if="formData.modificationsAndAccessories.haveVehiclesBeenModified === 'Yes'">-->
          <!--            <div class="form-group row mb-1">-->
          <!--              <div class="col-12 mb-1">-->
          <!--                <label class="col-form-label text-right">-->
          <!--                  <strong>-->
          <!--                    Description of Modifications<span v-if="!isUwMode" class="text-danger">*</span>-->
          <!--                  </strong>-->
          <!--                </label>-->
          <!--                <ValidationProvider :rules="{ required: !isUwMode, max: 2000 }" name="modificationsAndAccessories.descriptionOfVehicleModifications" v-slot="{ errors }">-->
          <!--                  <b-form-textarea-->
          <!--                    id="modificationsAndAccessories-descriptionOfVehicleModifications"-->
          <!--                    name="modificationsAndAccessories.descriptionOfVehicleModifications"-->
          <!--                    type="text"-->
          <!--                    v-model="formData.modificationsAndAccessories.descriptionOfVehicleModifications"-->
          <!--                    rows="4"-->
          <!--                    :disabled="isReadOnly"-->
          <!--                  ></b-form-textarea>-->
          <!--                  <span class="text-danger">{{ errors[0] }}</span>-->
          <!--                </ValidationProvider>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="form-group row mb-1">-->
          <!--              <div class="col-6 mb-1">-->
          <!--                <label class="col-form-label text-right">-->
          <!--                  <strong>-->
          <!--                    Number of Vehicles Modified<span v-if="!isUwMode" class="text-danger">*</span>-->
          <!--                  </strong>-->
          <!--                </label>-->
          <!--                <ValidationProvider :rules="{ required: !isUwMode }" v-slot="{ errors }">-->
          <!--                  <input class="form-control" type="number" min="1" :disabled="isReadOnly"-->
          <!--                         v-model.number="formData.modificationsAndAccessories.noOfVehiclesModified"-->
          <!--                  />-->
          <!--                  <span class="text-danger">{{ errors[0] }}</span>-->
          <!--                </ValidationProvider>-->
          <!--              </div>-->
          <!--              <div class="col-6 mb-1">-->
          <!--                <label class="col-form-label text-right">-->
          <!--                  <strong>-->
          <!--                    Average Market Value of Modifications<span v-if="!isUwMode" class="text-danger">*</span>-->
          <!--                  </strong>-->
          <!--                </label>-->
          <!--                <ValidationProvider :rules="{ required: !isUwMode }" v-slot="{ errors }">-->
          <!--                  <currency-input class="form-control" :disabled="isReadOnly"-->
          <!--                    v-model="formData.modificationsAndAccessories.averageMarketValueOfModifications"-->
          <!--                  />-->
          <!--                  <span class="text-danger">{{ errors[0] }}</span>-->
          <!--                </ValidationProvider>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </b-card>
      </div>
      <!-- end: GPS/Telematics -->

      <!-- begin: Finance section for Renewal Declaration Form-->
      <div v-if="isRenewal" class="mt-5">
        <h3>FINANCE</h3>
        <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
        <div class="form-group row mb-1">
          <div class="form-group col-12 mb-1">
            <label class="col-form-label text-right">
              <strong>
                Are any of the vehicles subject to finance?<span v-if="!isUwMode" class="text-danger">*</span>
              </strong>
            </label>

            <b-form-group class="mb-1">
              <ValidationProvider :rules="{ required: !isUwMode }" name="finance.areVehiclesSubjectToFinance" v-slot="{ errors }">
                <b-form-radio-group
                    id="finance-areVehiclesSubjectToFinance" name="finance.areVehiclesSubjectToFinance"
                    v-model="formData.finance.areVehiclesSubjectToFinance" type="radio" :disabled="isReadOnly"
                >
                  <b-form-radio type="radio" value="Yes">Yes</b-form-radio>
                  <b-form-radio type="radio" value="No">No</b-form-radio>
                </b-form-radio-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </div>
        </div>

        <div v-if="formData.finance.areVehiclesSubjectToFinance === 'Yes'">
          <div class="form-group row mb-1">
            <div class="form-group col-4 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  What % of the fleet is financed? <span v-if="!isUwMode" class="text-danger">*</span>
                </strong>
              </label>
              <ValidationProvider :rules="{ required: !isUwMode }" name="finance.percentageOfFleetFinanced" v-slot="{ errors }">
                <b-form-select
                    class="custom-select custom-select-md" name="finance.percentageOfFleetFinanced"
                    v-model="formData.finance.percentageOfFleetFinanced" :disabled="isReadOnly"
                >
                  <option value="1-10%">1-10%</option>
                  <option value="11-20%">11-20%</option>
                  <option value="21-30%">21-30%</option>
                  <option value="31-40%">31-40%</option>
                  <option value="41-50%">41-50%</option>
                  <option value="51% or Over">51% or Over</option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-1">
            <div class="form-group col-7 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  Type of Finance<span v-if="!isUwMode" class="text-danger">*</span>
                </strong>
              </label>

              <b-form-group class="mb-1">
                <ValidationProvider :rules="{ required: !isUwMode }" name="finance.typeOfFinance" v-slot="{ errors }">
                  <b-form-radio-group
                      id="finance-typeOfFinance" name="finance.typeOfFinance" v-model="formData.finance.typeOfFinance"
                      :disabled="isReadOnly"
                  >
                    <b-form-radio type="radio" value="Hire Purchase">Hire Purchase</b-form-radio>
                    <b-form-radio type="radio" value="Financial Lease">Financial Lease</b-form-radio>
                    <b-form-radio type="radio" value="Operating Lease">Operating Lease</b-form-radio>
                    <b-form-radio type="radio" value="Other">Other</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>

            <div class="form-group col-5 mb-1" v-if="formData.finance.typeOfFinance === 'Other'">
              <label class="col-form-label text-right">
                <strong>
                  Other type of Finance details<span v-if="!isUwMode" class="text-danger">*</span>
                </strong>
              </label>
              <ValidationProvider :rules="{ required: !isUwMode, max: 255 }" name="finance.otherFinanceDetails" v-slot="{ errors }">
                <input
                    class="form-control" v-model="formData.finance.otherFinanceDetails" type="text"
                    placeholder="Please specify the type of Finance" :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-1">
            <div class="form-group col-7 mb-1">
              <label class="col-form-label text-right">
                <strong>
                  How many months are the vehicles financed?<span v-if="!isUwMode" class="text-danger">*</span>
                </strong>
              </label>

              <b-form-group class="mb-1">
                <ValidationProvider :rules="{ required: !isUwMode }" name="finance.noOfMonthsVehiclesFinanced" v-slot="{ errors }">
                  <b-form-radio-group
                      id="requirementsOfInsurance-typeOfFinance"  name="finance.noOfMonthsVehiclesFinanced"
                      v-model="formData.finance.noOfMonthsVehiclesFinanced" type="radio" :disabled="isReadOnly"
                  >
                    <b-form-radio type="radio" value="12">12</b-form-radio>
                    <b-form-radio type="radio" value="24">24</b-form-radio>
                    <b-form-radio type="radio" value="36">36</b-form-radio>
                    <b-form-radio type="radio" value="Other">Other</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>

            <div class="form-group col-5 mb-1" v-if="formData.finance.noOfMonthsVehiclesFinanced === 'Other'">
              <label class="col-form-label text-right">
                <strong>Other number of months<span v-if="!isUwMode" class="text-danger">*</span>
                </strong>
              </label>
              <ValidationProvider :rules="{ required: !isUwMode }" name="finance.otherNumberOfMonthsVehiclesFinanced" v-slot="{ errors }">
                <input class="form-control" type="number" v-model="formData.finance.otherNumberOfMonthsVehiclesFinanced"
                       placeholder="Please specify the number of months" :disabled="isReadOnly"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </b-card>
      </div>
      <!-- begin: Finance section for Renewal Declaration Form -->

      <!-- begin: Rental Agreement for New Business Proposal Form -->
      <div v-if="!isRenewal && formData.brand !== 'Fuse'" class="mt-5">
        <h3>RENTAL AGREEMENT</h3>
        <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
          <div class="mb-2">
            <label class="col-form-label text-left">
              <strong>
                Please upload a copy of your current Rental Vehicle Agreement<span class="text-danger">*</span>
              </strong>
            </label>
            <br/>
            <a :href="rentalAgreementLink" target="_blank"><u>Click here</u></a> to access Sample Rental Agreement for Self-Drive.
            <br><br>
            <strong> Note:</strong> We do not provide specific advice on rental agreement, only general advice.
            You should contact your solicitor to finalise a contract suitable for your business. No Lease agreement accepted.<br>
          </div>

<!--          <file-upload :file-key="'rentalVehicleAgreement'" :form-data="formData" :is-read-only="isReadOnly"></file-upload>-->
          <files-upload
              :upload-key="'rentalVehicleAgreement'" :uploaded-files="formData.formUploads.rentalVehicleAgreement"
              :upload-in-progress="uploadProgress.rentalVehicleAgreement" :is-read-only="isReadOnly" v-on="$listeners">
          </files-upload>
        </b-card>
      </div>
      <!-- end: Rental Agreement for New Business Proposal Form -->

    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import FilesUpload from "@/view/pages/wizard/motor-proposal-form/components/FilesUpload.vue";
import FleetUpload from "@/view/pages/wizard/components/FleetUpload.vue";

export default {
  name: "WizardPage6",

  components: { FleetUpload, FilesUpload},

  props: {
    formId: String,
    formData: Object,
    referenceData: Object,
    uploadProgress: Object,
    isReadOnly: Boolean,
    isRenewal: Boolean,
    isUwMode: Boolean
  },

  data() {
    return {
    };
  },

  computed: {
    branchCodes: function() {
      let branchCodes = [];
      if(this.formData && Array.isArray(this.formData.rentalLocationsList)) {
        branchCodes = _.map(this.formData.rentalLocationsList, 'branchCode');
      }
      for (let [i, branchCode] of branchCodes.entries()) {
        if(branchCode) {
          branchCodes[i] = branchCode.toUpperCase();
        }
      }
      return branchCodes;
    },

    rentalAgreementLink: function () {
      if(this.formData) {
        let brand = _.toUpper(_.get(this.formData, 'brand'));
        if(brand === 'RENTSURE') {
          return 'https://link.rentsure.com.au/sample-rental-agreement';
        }
        else if(brand === 'CRI') {
          return 'https://link.crinsurance.com.au/sample-rental-agreement';
        }
      }
      return '';
    }
  },

  methods: {
    addVehicleTypeEntry: function () {
      this.formData.fleetTypes.push({});
    },

    deleteVehicleTypeEntry: function (index) {
      this.formData.fleetTypes.splice(index, 1);
    },

    calculateTotalFleet: function (index) {
      this.formData.fleetHistory[index].totalFleet =
        parseFloat(this.formData.fleetHistory[index].comprehensive || 0) +
        parseFloat(this.formData.fleetHistory[index].tppd || 0);
    },

    getPreviousPeriodFleet: function () {
      this.$emit('getPreviousPeriodFleet');
    },

    getFormUploadedFleet: function () {
      this.$emit('getFormUploadedFleet');
    }
  }
};
</script>
