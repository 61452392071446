<template>
  <ValidationObserver ref="clientContact">
  <div class="form-group mb-5">
    <div class="form-group row mb-1">
      <div class="col-6">
        <label class="col-form-label text-left">
          First Name<span v-if="mandatory" class="text-danger">*</span>
        </label>
        <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="clientContactEntry.firstName" v-slot="{ errors }">
          <input class="form-control" v-model="contactDetails.firstName" :disabled="isReadOnly" maxlength="255"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">
          Last Name<span v-if="mandatory" class="text-danger">*</span>
        </label>
        <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="clientContactEntry.lastName" v-slot="{ errors }">
          <input class="form-control" v-model="contactDetails.lastName" :disabled="isReadOnly" maxlength="255"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="form-group row mb-1">
      <div class="col-6">
        <label class="col-form-label text-left">
          Title/Company Position<span v-if="mandatory" class="text-danger">*</span>
        </label>
        <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="clientContactEntry.title" v-slot="{ errors }">
          <input class="form-control" v-model="contactDetails.title" :disabled="isReadOnly" maxlength="255"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">Email<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required|email|max:100' : 'email|max:100'" name="clientContactEntry.email" v-slot="{ errors }">
          <input type="email" class="form-control" v-model="contactDetails.email" :disabled="isReadOnly" maxlength="100"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="form-group row mb-1">
      <div class="col-6">
        <label class="col-form-label text-left">Phone<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required|max:15' : 'max:15'" name="clientContactEntry.phone" v-slot="{ errors }">
          <input class="form-control" v-model="contactDetails.phone" :disabled="isReadOnly"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">Mobile<span v-if="mandatory" class="text-danger">*</span></label>
        <ValidationProvider :rules="mandatory ? 'required|max:15' : 'max:15'" name="clientContactEntry.mobile" v-slot="{ errors }">
          <input class="form-control" v-model="contactDetails.mobile" :disabled="isReadOnly"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>

export default {
  name: "ClientContact",

  props: {
    contactDetails: Object,
    mandatory: Boolean,
    isReadOnly: Boolean
  },

  data() {
    return {
    };
  }
};
</script>
