<template>
<ValidationObserver ref="currentInsuranceDetails">
  <div class="mb-10 pb-5font-weight-bold text-dark">

    <!-- begin: Current Insurance Details -->
    <h3>CURRENT INSURANCE DETAILS</h3>
    <b-card
      border-variant="primary"
      class="mb-5 mt-3"
      body-class="px-7 py-5"
    >
      <div class="form-group row mb-1 mt-2">
        <div class="col-6 mb-1">
          <label class="col-form-label text-right">
            <strong>Current Insurer <span v-if="!isUwMode" class="text-danger">*</span></strong>
          </label>
          <ValidationProvider :rules="{ required: !isUwMode, max: 255 }" name="currentInsuranceDetails.currentInsurer" v-slot="{ errors }">
            <input class="form-control" type="text" v-model="formData.currentInsuranceDetails.currentInsurer" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <b-form-text id="input-help-currentInsurer" class="font-italic">
            If not currently insured, please state NONE.
          </b-form-text>
        </div>
<!--        <div class="col-6 mb-1">-->
<!--          <label class="col-form-label text-right">-->
<!--            <strong> Broker Name </strong>-->
<!--          </label>-->
<!--          <input class="form-control" v-model="formData.currentInsuranceDetails.brokerName" :disabled="isReadOnly"/>-->
<!--          <b-form-text id="input-help-currentInsurer" class="font-italic">-->
<!--            If applicable-->
<!--          </b-form-text>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group row mb-1">-->
<!--        <div class="col-6 mb-1">-->
<!--          <label class="col-form-label text-right">-->
<!--            <strong> Policy Number </strong>-->
<!--          </label>-->
<!--          <input class="form-control" v-model="formData.currentInsuranceDetails.policyNumber" :disabled="isReadOnly"/>-->
<!--        </div>-->
        <div class="col-6 mb-1">
          <label class="col-form-label text-right">
            <strong> Expiry Date </strong>
          </label>
          <b-form-datepicker
            id="currentInsuranceDetails-expiryDate"
            class="form-control form-control-sm col-5"
            v-model="formData.currentInsuranceDetails.expiryDate"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            locale="en-AU"
            :disabled="isReadOnly"
          >
          </b-form-datepicker>
        </div>
      </div>
<!--      <div class="form-group row mb-1">-->
<!--        <div class="form-group col-12 mb-1">-->
<!--          <label class="col-form-label text-right">-->
<!--            <strong>-->
<!--              Are there any special excesses and/or conditions applicable?-->
<!--            </strong>-->
<!--          </label>-->
<!--          <ValidationProvider rules="max:2000" name="currentInsuranceDetails.specialExcessesAndConditions" v-slot="{ errors }">-->
<!--            <b-form-textarea-->
<!--              id="currentInsuranceDetails-specialExcessesAndConditions"-->
<!--              v-model="formData.currentInsuranceDetails.specialExcessesAndConditions"-->
<!--              rows="3"-->
<!--              :disabled="isReadOnly"-->
<!--            ></b-form-textarea>-->
<!--            <span class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </div>-->
<!--      </div>-->
    </b-card>
    <!-- end: Current Insurance Details -->
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>

export default {
  name: "CurrentInsuranceDetails",

  props: {
    formData: Object,
    isReadOnly: Boolean,
    isUwMode: Boolean
  }
};
</script>
