<template>
<ValidationObserver :ref="`rentalLocation${reference}`">
  <div class="form-group">
    <div class="form-group row mb-0">
      <div class="col-12">
        <address-details :address-entity="locationEntry.addressDetails" :mandatory="true" :is-read-only="isReadOnly"></address-details>
      </div>
    </div>

    <div class="form-group row mb-1">
      <div class="col-6">
        <label class="col-form-label text-left">Location Type<span class="text-danger">*</span></label>
        <ValidationProvider rules="required" :name="`locationEntry${reference}.locationType`" v-slot="{ errors }">
          <b-form-select class="custom-select custom-select-md" v-model="locationEntry.locationType" :disabled="isReadOnly">
            <option value="Head Office" :disabled="!allowHeadOfficeSelection">Head Office</option>
            <option value="Branch">Branch</option>
          </b-form-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <label class="col-form-label text-left">Location/Branch Code<span class="text-danger">*</span></label>
        <ValidationProvider :rules="{ required: true, max: 30, regex: BRANCH_CODE_REGEX }" :name="`locationEntry${reference}.branchCode`" v-slot="{ errors }">
          <input class="form-control text-uppercase" v-model="locationEntry.branchCode" :disabled="isReadOnly"
                 maxlength="30" @input="formatBranchCode(locationEntry)"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

<!--    <div class="form-group row mb-1">-->
<!--      <div class="form-group col-12 mb-1">-->
<!--        <label class="col-form-label text-right">-->
<!--          <strong>-->
<!--            Does the premises have a monitored back to base alarm system?<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--          </strong>-->
<!--        </label>-->
<!--        <b-form-group class="mb-1">-->
<!--          <ValidationProvider :rules="{ required: !isUwMode }" :name="`locationEntry${reference}.doesPremisesHaveBaseAlarmSystem`" v-slot="{ errors }">-->
<!--            <b-form-radio-group v-model="locationEntry.doesPremisesHaveBaseAlarmSystem" type="radio" :disabled="isReadOnly">-->
<!--              <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
<!--              <b-form-radio type="radio" value="No">No</b-form-radio>-->
<!--            </b-form-radio-group>-->
<!--            <span class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-if="locationEntry.doesPremisesHaveBaseAlarmSystem === 'No'">-->
<!--      <div class="form-group row mb-1">-->
<!--        <div class="form-group col-12 mb-1">-->
<!--          <label class="col-form-label text-right">-->
<!--            <strong>-->
<!--              Does the premises have a local alarm system?<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--            </strong>-->
<!--          </label>-->
<!--          <b-form-group class="mb-1">-->
<!--            <ValidationProvider :rules="{ required: !isUwMode }" :name="`locationEntry${reference}.doesPremisesHaveLocalAlarmSystem`" v-slot="{ errors }">-->
<!--              <b-form-radio-group v-model="locationEntry.doesPremisesHaveLocalAlarmSystem" type="radio" :disabled="isReadOnly">-->
<!--                <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
<!--                <b-form-radio type="radio" value="No">No</b-form-radio>-->
<!--              </b-form-radio-group>-->
<!--              <span class="text-danger">{{ errors[0] }}</span>-->
<!--            </ValidationProvider>-->
<!--          </b-form-group>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="form-group row mb-1">-->
<!--      <div class="form-group col-12 mb-1">-->
<!--        <label class="col-form-label text-right">-->
<!--          <strong>-->
<!--            Is this premises fully fenced and locked overnight?<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--          </strong>-->
<!--        </label>-->
<!--        <b-form-group class="mb-1">-->
<!--          <ValidationProvider :rules="{ required: !isUwMode }" :name="`locationEntry${reference}.isPremisesFullyFencedAndLocked`" v-slot="{ errors }">-->
<!--            <b-form-radio-group v-model="locationEntry.isPremisesFullyFencedAndLocked" type="radio" :disabled="isReadOnly">-->
<!--              <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
<!--              <b-form-radio type="radio" value="No">No</b-form-radio>-->
<!--            </b-form-radio-group>-->
<!--            <span class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="form-group row mb-1">-->
<!--      <div class="form-group col-12 mb-1">-->
<!--        <label class="col-form-label text-right">-->
<!--          <strong>-->
<!--            Is this premises in a Flood Zone or has previously been affected by Flood?<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--          </strong>-->
<!--        </label>-->
<!--        <b-form-group class="mb-1">-->
<!--          <ValidationProvider :rules="{ required: !isUwMode }" :name="`locationEntry${reference}.isPremisesInFloodZone`" v-slot="{ errors }">-->
<!--            <b-form-radio-group v-model="locationEntry.isPremisesInFloodZone" type="radio" :disabled="isReadOnly">-->
<!--              <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
<!--              <b-form-radio type="radio" value="No">No</b-form-radio>-->
<!--              <b-form-radio value="Don't Know">Don't Know</b-form-radio>-->
<!--            </b-form-radio-group>-->
<!--            <span id="error" class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="form-group row mb-1">-->
<!--      <div class="form-group col-6 mb-1">-->
<!--        <label class="col-form-label text-right">-->
<!--          <strong>Do you have hail nets?<span v-if="!isUwMode" class="text-danger">*</span></strong>-->
<!--        </label>-->
<!--        <b-form-group class="mb-1">-->
<!--          <ValidationProvider :rules="{ required: !isUwMode }" :name="`locationEntry${reference}.hailNets`" v-slot="{ errors }">-->
<!--            <b-form-radio-group v-model="locationEntry.hailNets" type="radio" :disabled="isReadOnly">-->
<!--              <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
<!--              <b-form-radio type="radio" value="No">No</b-form-radio>-->
<!--            </b-form-radio-group>-->
<!--            <span class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--      <div class="form-group col-6 mb-1" v-if="locationEntry.hailNets === 'Yes'">-->
<!--        <label class="col-form-label text-right">-->
<!--          <strong>-->
<!--            Percentage of vehicles stored under the hail nets<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--          </strong>-->
<!--        </label>-->
<!--        <b-form-group class="mb-1">-->
<!--          <ValidationProvider :rules="{ required: !isUwMode, min_value:0, max_value: 100 }" :name="`locationEntry${reference}.percentOfVehiclesUnderHailNets`" v-slot="{ errors }">-->
<!--            <input type="number" min="0" max="100" class="form-control" v-model="locationEntry.percentOfVehiclesUnderHailNets" :disabled="isReadOnly"/>-->
<!--            <span class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="form-group row mb-1">-->
<!--      <div class="form-group col-6 mb-1">-->
<!--        <label class="col-form-label text-right">-->
<!--          <strong>-->
<!--            Are any of your vehicles stored under cover?<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--          </strong>-->
<!--        </label>-->
<!--        <b-form-group class="mb-1">-->
<!--          <ValidationProvider :rules="{ required: !isUwMode }" :name="`locationEntry${reference}.storedUnderCover`" v-slot="{ errors }">-->
<!--            <b-form-radio-group v-model="locationEntry.storedUnderCover" type="radio" :disabled="isReadOnly">-->
<!--              <b-form-radio type="radio" value="Yes">Yes</b-form-radio>-->
<!--              <b-form-radio type="radio" value="No">No</b-form-radio>-->
<!--            </b-form-radio-group>-->
<!--            <span class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--      <div class="form-group col-6 mb-1" v-if="locationEntry.storedUnderCover === 'Yes'">-->
<!--        <label class="col-form-label text-right">-->
<!--          <strong>-->
<!--            Percentage of vehicles stored under cover<span v-if="!isUwMode" class="text-danger">*</span>-->
<!--          </strong>-->
<!--        </label>-->
<!--        <b-form-group class="mb-1">-->
<!--          <ValidationProvider :rules="{ required: !isUwMode, min_value:0, max_value: 100 }" :name="`locationEntry${reference}.percentOfVehiclesStoredUnderCover`" v-slot="{ errors }">-->
<!--            <input type="number" min="0" max="100" class="form-control" v-model="locationEntry.percentOfVehiclesStoredUnderCover" :disabled="isReadOnly"/>-->
<!--            <span class="text-danger">{{ errors[0] }}</span>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import AddressDetails from "@/view/pages/wizard/components/AddressDetails.vue";

export default {
  name: "RentalLocation",

  components: {
    AddressDetails
  },

  props: {
    locationEntry: Object,
    reference: String,
    isReadOnly: Boolean,
    allowHeadOfficeSelection: {
      type: Boolean,
      default: true,
    },
    isUwMode: Boolean
  },

  data() {
    return {
      postalAddressOptions: [
        { text: "Yes", value: "samePostalAddress" },
        { text: "No", value: "differentPostalAddress" },
      ],
    };
  },

  methods : {
    formatBranchCode: function(location) {
      if(location.K_BranchCode) {
        location.K_BranchCode = location.K_BranchCode.toUpperCase().replace(/[^0-9a-zA-Z_-]/gi, '').trim();
      }
    }
  },

  computed: {
    BRANCH_CODE_REGEX: function () {
      return /^[a-zA-Z0-9-_]+$/;
    }
  }
};
</script>
