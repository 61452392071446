<template>
<ValidationObserver ref="wizardPage4" id="wizardPage4" name="wizardPage4">
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <div class="mb-10 font-weight-bold text-dark" v-if="formData && referenceData">

      <div v-if="formData.brand !== 'Fuse'">
        <h3>RENTAL LOCATIONS</h3>
        <p class="font-italic">
          Please ensure you note all rental premises and vehicle storage locations
        </p>
      </div>
    <div v-if="formData.brand === 'Fuse'">
    <h3>BUSINESS LOCATIONS</h3>
    <p class="font-italic">
      Please ensure you note all vehicle storage locations
    </p>
  </div>

      <!-- begin: Business Locations-->
      <b-card border-variant="primary" class="mb-5 mt-3" body-class="px-7 py-5">
        <table>
          <thead>
            <th style="width: 30%"></th>
          </thead>
          <tr v-for="(rentalLocation, index) in formData.rentalLocationsList" :key="index">
            <td>
              <b-card class="mb-4" bg-variant="light" body-class="p-4">
                <div class="row">
                  <div class="col-12">
                    <label class="text-left">Location {{ index + 1 }}</label>
                    <button class="btn btn-danger m-2 float-right" @click.prevent="removeRentalLocationEntry(index)" v-if="!isReadOnly">
                      Remove
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <rental-location :location-entry="rentalLocation" :is-uw-mode="isUwMode" :is-read-only="isReadOnly"
                                     :allow-head-office-selection="allowHeadOfficeSelection"
                    />
                  </div>
                </div>
              </b-card>
            </td>
          </tr>
        </table>
        <div>
          <button class="btn btn-primary m-2" @click.prevent="addRentalLocationEntry()" v-if="!isReadOnly">
            Add Another Location
          </button>
        </div>
        <ValidationProvider rules="min_count:1" name="numberOfRentalLocations" v-slot="{ errors }">
          <input type="number" v-model="formData.rentalLocationsList.length" hidden/>
          <span class="text-danger"><br>{{ errors[0] }}</span>
        </ValidationProvider>
      </b-card>
      <!-- end: Business Locations-->

    </div>
  </div>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import RentalLocation from "@/view/pages/wizard/motor-proposal-form/components/RentalLocation.vue";

export default {
  name: "WizardPage4",

  components: {
    RentalLocation,
  },

  props: {
    formData: Object,
    referenceData: Object,
    isReadOnly: Boolean,
    isRenewal: Boolean,
    isUwMode: Boolean
  },

  data() {
    return {
    };
  },

  methods: {
    addRentalLocationEntry: function () {
      this.formData.rentalLocationsList.push({ addressDetails: {} });
    },

    removeRentalLocationEntry: function (index) {
      this.formData.rentalLocationsList.splice(index, 1);
    },
  },

  computed: {
    allowHeadOfficeSelection: function () {
      if(this.formData && Array.isArray(this.formData.rentalLocationsList)) {
        return !_.some(this.formData.rentalLocationsList, { 'locationType': 'Head Office'});
      }
      return true;
    }
  }
};
</script>
