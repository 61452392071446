var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:("insuredEntity" + _vm.reference)},[_c('div',{staticClass:"form-group"},[(_vm.showAbnSearch)?_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"col-6 row"},[_c('label',{staticClass:"col-3 col-form-label"},[_vm._v("Search ABN"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('span',{staticClass:"col-5"},[_c('ValidationProvider',{attrs:{"rules":{ regex: /^[\d\s]+$/ },"name":("abnSearch" + _vm.reference)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.abnSearchString),expression:"abnSearchString"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.isReadOnly,"maxlength":"15"},domProps:{"value":(_vm.abnSearchString)},on:{"input":function($event){if($event.target.composing){ return; }_vm.abnSearchString=$event.target.value}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4071397606)})],1),_c('button',{staticClass:"ml-3 col-3 btn btn-outline-primary form-control",attrs:{"type":"button","disabled":_vm.searchingAbn || _vm.isReadOnly || !_vm.abnSearchString},on:{"click":function($event){$event.preventDefault();return _vm.searchAbn.apply(null, arguments)}}},[_vm._v("Search")]),(_vm.searchingAbn)?_c('b-spinner',{staticClass:"ml-3 mt-1",attrs:{"variant":"primary"}}):_vm._e()],1)]):_vm._e(),(_vm.abnSearchError)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.abnSearchError))])]):_vm._e(),_c('div',{staticClass:"form-group row mb-1 mt-5"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("Full Insured Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":"required|max:255","name":("legalEntity" + _vm.reference + ".fullInsuredName")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalEntity.fullInsuredName),expression:"legalEntity.fullInsuredName"}],staticClass:"form-control",attrs:{"type":"text","disabled":"","maxlength":"255"},domProps:{"value":(_vm.legalEntity.fullInsuredName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.legalEntity, "fullInsuredName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("ABN"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"rules":"required","name":("legalEntity" + _vm.reference + ".ABN")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalEntity.ABN),expression:"legalEntity.ABN"}],staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.legalEntity.ABN)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.legalEntity, "ABN", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("ACN")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalEntity.ACN),expression:"legalEntity.ACN"}],staticClass:"form-control",attrs:{"disabled":""},domProps:{"value":(_vm.legalEntity.ACN)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.legalEntity, "ACN", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"form-group col-6 mb-1"},[_c('label',{staticClass:"col-form-label text-right"},[_vm._v("Are you registered for GST?"),(!_vm.isUwMode)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('b-form-group',{staticClass:"mb-1"},[_c('ValidationProvider',{attrs:{"rules":{ required: !_vm.isUwMode },"name":("legalEntity" + _vm.reference + ".isGSTregistered")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"type":"radio","disabled":_vm.isReadOnly},model:{value:(_vm.legalEntity.isGSTregistered),callback:function ($$v) {_vm.$set(_vm.legalEntity, "isGSTregistered", $$v)},expression:"legalEntity.isGSTregistered"}},[_c('b-form-radio',{attrs:{"value":"entityGSTregistered"}},[_vm._v("Yes")]),_c('b-form-radio',{attrs:{"value":"entityGSTnotRegistered"}},[_vm._v("No")])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.legalEntity.isGSTregistered === 'entityGSTregistered')?_c('div',{staticClass:"form-group col-6 mb-1"},[_c('label',{staticClass:"col-form-label text-right"},[_vm._v("Input Tax Credit (%)"),(!_vm.isUwMode)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":{ required: !_vm.isUwMode, max_value: 100 },"name":("legalEntity" + _vm.reference + ".inputTaxCredit")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalEntity.inputTaxCredit),expression:"legalEntity.inputTaxCredit"}],staticClass:"form-control",attrs:{"type":"number","min":"0","max":"100","disabled":_vm.isReadOnly},domProps:{"value":(_vm.legalEntity.inputTaxCredit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.legalEntity, "inputTaxCredit", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1604535113)})],1):_vm._e()]),_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("Trading Name"),(!_vm.isUwMode)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":{ required: !_vm.isUwMode, max: 255 },"name":("legalEntity" + _vm.reference + ".tradingName")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalEntity.tradingName),expression:"legalEntity.tradingName"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.isReadOnly},domProps:{"value":(_vm.legalEntity.tradingName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.legalEntity, "tradingName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group row",attrs:{"mb-1":""}},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"col-form-label text-left"},[_vm._v("Website")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalEntity.website),expression:"legalEntity.website"}],staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly},domProps:{"value":(_vm.legalEntity.website)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.legalEntity, "website", $event.target.value)}}}),_c('b-form-text',{staticClass:"font-italic"},[_vm._v(" Please ensure the website URL is in the following format: https://www.name.com ")])],1)]),(_vm.showAddress === true)?_c('div',[_vm._v(" Street Address "),_c('div',{staticClass:"form-group row mb-1"},[_c('div',{staticClass:"col-12"},[_c('address-details',{attrs:{"address-entity":_vm.legalEntity.addressDetails,"is-read-only":_vm.isReadOnly,"mandatory":true}})],1)]),_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Is the Postal Address the same as the Street Address?"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('ValidationProvider',{attrs:{"rules":"required","name":("legalEntity" + _vm.reference + ".isPostalAddressSame")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"aria-describedby":ariaDescribedby,"button-variant":"outline-primary","size":"sm","disabled":_vm.isReadOnly},model:{value:(_vm.legalEntity.isPostalAddressSame),callback:function ($$v) {_vm.$set(_vm.legalEntity, "isPostalAddressSame", $$v)},expression:"legalEntity.isPostalAddressSame"}},[_c('b-form-radio',{attrs:{"value":"samePostalAddress"}},[_vm._v("Yes")]),_c('b-form-radio',{attrs:{"value":"differentPostalAddress"}},[_vm._v("No")])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}],null,false,3659058760)}),(_vm.legalEntity.isPostalAddressSame === 'differentPostalAddress')?_c('div',[_vm._v(" Postal Address "),_c('address-details',{attrs:{"address-entity":_vm.legalEntity.postalAddressDetails,"is-read-only":_vm.isReadOnly,"mandatory":true}})],1):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }